import { Dialog } from '@headlessui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AlertIcon from '@/assets/images/alert-triangle.svg';
import Arrow from '@/assets/images/arrow-up.svg';
import BlueFileIcon from '@/assets/images/blue-file-icon.svg';
import CautionBubble from '@/assets/images/caution-bubble.svg';
import TrashIcon from '@/assets/images/delete-icon.png';
import EllipsisIcon from '@/assets/images/ellipsis.png';
import ExportIcon from '@/assets/images/export.svg';
import MissingDocsIcon from '@/assets/images/file.svg';
import GreenCheck from '@/assets/images/greencheck.svg';
import GreenFile from '@/assets/images/greenfile.svg';
import RedFile from '@/assets/images/redfile.svg';
import { Dropdown } from '@/components/Dropdown';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { SimpleButton } from '@/pages/overview/dataroom/content/common/SimpleButton';
import { PdfViewer } from '@/pages/pdf-viewer/PdfViewer';
import { SlideInPdfViewer } from '@/pages/pdf-viewer/SlideInPdfViewer';
import { trpcClient, trpcReact } from '@/utils/trpc';

type Dict<TType> = {
  [x: string]: TType | undefined;
};

type Target = {
  title: string;
  match: boolean;
  name?: string;
  displayName?: string;
};

type Source = {
  displayName: string;
  name: string;
  locations: number[];
};

type PossibleRef = {
  reference: string;
  similarity: number;
};

type MatchReasoning = {
  match: boolean;
  reasoning: string;
};

type Summary = {
  summary: string;
  title: string;
};

type ReferencedDocument = {
  target: Target;
  status: string;
  summary: Summary;
  source: Source;
  quote: string;
  clusterId: number;
  nameClusterId: number;
  topK: PossibleRef[];
  reasoning: Dict<MatchReasoning>;
  id: string;
};

interface CheckBoxProps {
  rowId: string;
}

const defaultData: ReferencedDocument[] = [];

const columnHelper = createColumnHelper<ReferencedDocument>();

export const MissingDocumentsContent = () => {
  const flags = useFlags();
  const { matter, setIsPanelCollapsed } = useOverview();
  const { secondSelectedFile, secondSelectedFileUrl, selectSecondFileByName } = useDataRoom();
  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const { selectedFile, setSelectedFile, selectedFileUrl, dataRoomFiles } = useDataRoom();

  const [data, _setData] = useState(() => [...defaultData]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>();
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState<string[]>([]);
  const [viewAllDocuments, setViewAllDocuments] = useState(false);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    clusterId: false,
    nameClusterId: false,
    target: true,
    status: false,
    summary: true,
    quote: true,
    topK: false,
    reasoning: false,
    source: true,
    id: true,
  });

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'target', desc: false }, // target sort fn is mapped to nameClusterId
    { id: 'source', desc: false },
    { id: 'clusterId', desc: false },
  ]);

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[], rowIndex: number) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsSlideViewerOpen(true);
        setIsPanelCollapsed(true);
        setSelectedRowIndex(rowIndex);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  const deleteRefDoc = useCallback(
    (refDocId: string) => {
      selectedRowArray.filter((rowId) => rowId !== refDocId);
      trpcClient.dataRoom.deleteReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
      });
    },
    [matter.client.number, matter.number, selectedRowArray],
  );

  const markRefDocMissing = useCallback(
    (refDocId: string) => {
      trpcClient.dataRoom.updateMissingReferencedDocById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
      });
    },
    [matter.client.number, matter.number],
  );

  const handleToggleCheckbox = (index: string) => {
    setSelectedRowArray((prevselectedRowArray) => {
      if (prevselectedRowArray.includes(index)) {
        return prevselectedRowArray.filter((i) => i !== index);
      } else {
        return [...prevselectedRowArray, index];
      }
    });
  };

  const CheckBox = useCallback(
    ({ rowId }: CheckBoxProps) => {
      return (
        <input
          type="checkbox"
          checked={selectedRowArray.includes(rowId)}
          onChange={() => handleToggleCheckbox(rowId)}
          className={`size-4 shrink-0 cursor-pointer appearance-none border border-light-border bg-marveri-background bg-contain bg-center bg-no-repeat checked:bg-marveri-gold checked:bg-[url('@/assets/images/check-icon-white.svg')]`}
          data-testid="data-room-file-checkbox"
        />
      );
    },
    [selectedRowArray],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('clusterId', {
        header: () => 'Cluster ID',
      }),
      columnHelper.accessor('nameClusterId', {
        header: () => 'Name Cluster ID',
      }),
      columnHelper.accessor('target', {
        cell: (info) =>
          info.getValue().match ? (
            <div className="flex -translate-x-4 cursor-pointer flex-row items-center justify-center gap-4 py-1 pr-3 text-marveri-white">
              <CheckBox rowId={info.row.original.id} />
              <img className="mr-4" src={GreenFile} />
              <span
                onClick={() => {
                  selectSourceFile(info.getValue().name || '', [], -1);
                }}
              >
                {info.getValue()?.displayName}
              </span>
            </div>
          ) : (
            <div className="flex -translate-x-4 flex-row items-center gap-4 py-1 pr-3 text-marveri-white">
              <CheckBox rowId={info.row.original.id} />
              <img src={RedFile} />
              {info.getValue()?.title}
            </div>
          ),
        header: () => <span>{viewAllDocuments ? 'Document' : 'Missing Document'} </span>,
        footer: (info) => info.column.id,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.nameClusterId < rowB.original.nameClusterId) {
            return -1;
          } else if (rowA.original.nameClusterId > rowB.original.nameClusterId) {
            return 1;
          } else {
            return 0;
          }
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        cell: (info) => (
          <div
            className={
              info.renderValue() === 'Missing'
                ? 'flex flex-row justify-center px-3 py-1 text-marveri-white'
                : 'flex flex-row justify-center px-3 py-1 text-marveri-white'
            }
          >
            <img className="mr-1" src={info.renderValue() === 'Missing' ? AlertIcon : GreenCheck} />
            {info.renderValue()}
          </div>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('summary', {
        header: () => (
          <span>
            {viewAllDocuments ? 'Description of Document' : 'Description of Missing Document'}
          </span>
        ),
        cell: (info) => {
          const summary = info.getValue();
          return (
            <div>
              <p>{summary.summary}</p>
            </div>
          );
        },
        footer: (info) => info.column.id,
        sortingFn: (rowA, rowB) => {
          if (rowA.original.clusterId < rowB.original.clusterId) {
            return -1;
          } else if (rowA.original.clusterId > rowB.original.clusterId) {
            return 1;
          } else {
            return 0;
          }
        },
      }),

      columnHelper.accessor('topK', {
        header: () => 'top_k',
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          return Array.isArray(val) ? (
            val.map((val, index) => (
              <>
                <div key={index}>
                  {val.reference} ({val.similarity})
                </div>
                <br />
              </>
            ))
          ) : (
            <></>
          );
        },
      }),
      columnHelper.accessor('reasoning', {
        header: () => 'reasoning',
        enableSorting: false,
        cell: (info) => {
          const val = info.getValue();
          const keys = Object.keys(val);
          return val ? (
            keys.map((key, index) => (
              <>
                <div key={index}>
                  <h1>
                    {key}: {val[key]?.match ? 'Match' : 'No Match'}
                  </h1>
                  <p>{val[key]?.reasoning}</p>
                </div>
                <br />
              </>
            ))
          ) : (
            <></>
          );
        },
      }),
      columnHelper.accessor('source', {
        header: 'Source',
        cell: (info) => (
          <div
            className="flex cursor-pointer flex-row justify-center hover:underline"
            onClick={() => {
              selectSourceFile(info.getValue().name, info.getValue().locations, info.row.index);
            }}
          >
            <img className="mr-4" src={BlueFileIcon} />
            {info.getValue()?.displayName}
          </div>
        ),
        footer: (info) => info.column.id,
        sortingFn: (rowA, rowB) => {
          if (
            (rowA.original.source?.displayName || '') < (rowB.original.source?.displayName || '')
          ) {
            return -1;
          } else if (
            (rowA.original.source?.displayName || '') > (rowB.original.source?.displayName || '')
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      }),
      columnHelper.accessor('id', {
        header: '',
        cell: (info) => {
          const options = [
            {
              icon: TrashIcon,
              title: 'Delete',
              onClick: () => deleteRefDoc(info.getValue()),
            },
          ];
          if (info.row.original.status === 'Available') {
            options.unshift({
              icon: MissingDocsIcon,
              title: 'Mark as Missing',
              onClick: () => markRefDocMissing(info.getValue()),
            });
          }
          return (
            <div className="w-14">
              <Dropdown
                testId="actionButton"
                title=""
                icon={EllipsisIcon}
                isButtonDisabled={false}
                options={options}
              />
            </div>
          );
        },
      }),
    ];
  }, [CheckBox, deleteRefDoc, markRefDocMissing, selectSourceFile, viewAllDocuments]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
      sorting,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    let rows = [] as ReferencedDocument[];
    rows = matter.dataRoomFiles
      .map((file) => {
        return file.mentions.map((mention) => {
          return {
            target: {
              title: mention.title,
              match: mention.status === 'Available',
              name: mention.targetDocument?.name,
              displayName: mention.targetDocument?.displayName,
            },
            source: {
              displayName: file.displayName,
              name: file.name,
              locations: mention.locations,
            },
            summary: {
              summary: mention.summary,
              title: mention.title,
            },
            status: mention.status,
            clusterId: mention.clusterId,
            nameClusterId: mention.nameClusterId,
            quote: mention.quote,
            topK: (mention.topK || []) as PossibleRef[],
            reasoning: (mention.reasoning || {}) as Dict<MatchReasoning>,
            id: mention.id,
          };
        });
      })
      .flat();

    _setData(rows);
  }, [matter]);

  const refreshDocs = useCallback(() => {
    trpcClient.dataRoom.recomputeMissingDocuments.mutate({
      clientMatterNumber: matter.number,
      clientNumber: matter.client.number,
    });
  }, [matter.client.number, matter.number]);

  const [isRefreshDialogOpen, setIsRefreshDialogOpen] = useState(false);

  const downloadTableAsExcel = useCallback(async () => {
    fetch('/Template Missing Documents Excel.xlsx')
      .then((response) => {
        return response.arrayBuffer();
      })
      .then(async (arrayBuffer) => {
        // Create a template
        const workbook = new Workbook();
        await workbook.xlsx.load(arrayBuffer);

        const sheet = workbook.worksheets[0];

        const client = sheet.getCell('D2');
        client.value = matter.client.name;
        const date = sheet.getCell('D3');
        date.value = new Date().toDateString();
        const generatedBy = sheet.getCell('D4');
        generatedBy.value = user?.firstName + ' ' + user?.lastName;

        const niceColumnLookup = {
          id: 'DB id',
          target: 'Referenced/Missing Document',
          status: 'Status',
          summary: 'Description of Referenced Document',
          source: 'Source Document',
          clusterId: 'Cluster ID',
          nameClusterId: 'Name Cluster ID',
          reasoning: 'Reasoning',
          quote: 'Quotation',
          topK: 'Top K Matches',
        } as Dict<string>;

        const rowLookups = [] as string[];
        const tableColumns = columns
          .filter((col) => {
            return (
              (columnVisibility[col.accessorKey] === true && col.accessorKey != 'id') ||
              col.accessorKey == 'status'
            );
          })
          .map((col) => {
            rowLookups.push(col.accessorKey as string);
            return {
              name: niceColumnLookup[col.accessorKey as string] || col.accessorKey,
              filterButton: true,
            };
          });

        tableColumns.push({
          name: 'Response',
          filterButton: true,
        });

        sheet.addTable({
          name: 'MyTable',
          ref: 'B7',
          headerRow: true,
          totalsRow: false,
          style: {
            theme: 'TableStyleLight1',
          },
          columns: tableColumns,
          rows: data
            .filter((row) => viewAllDocuments || row.status === 'Missing')
            .map((row) => {
              const rowResponse = [];
              rowLookups.forEach((accessor) => {
                if (accessor == 'target') {
                  rowResponse.push(row.target.displayName || row.target.title);
                }
                if (accessor == 'status') {
                  rowResponse.push(row.status);
                }
                if (accessor == 'id') {
                  // ignore this one
                }
                if (accessor == 'summary') {
                  rowResponse.push(row.summary.summary);
                }
                if (accessor == 'source') {
                  rowResponse.push(row.source.displayName);
                }
                if (accessor == 'quote') {
                  rowResponse.push(row.quote);
                }
                if (accessor == 'reasoning') {
                  rowResponse.push(row.reasoning);
                }
                if (accessor == 'topK') {
                  rowResponse.push(row.topK);
                }
                if (accessor == 'clusterId') {
                  rowResponse.push(row.clusterId);
                }
                if (accessor == 'nameClusterId') {
                  rowResponse.push(row.nameClusterId);
                }
              });
              rowResponse.push('');
              return rowResponse;
            }),
        });

        const buffer = await workbook.xlsx.writeBuffer();

        saveAs(
          new Blob([buffer]),
          `${new Date().toDateString()} ${matter.client.name} Missing Documents.xlsx`,
        );
      });
  }, [
    columnVisibility,
    columns,
    data,
    matter.client.name,
    user?.firstName,
    user?.lastName,
    viewAllDocuments,
  ]);

  const closeDialog = useCallback(() => {
    setIsRefreshDialogOpen(false);
  }, []);

  const handleDeleteAll = () => {
    setSelectedRowArray([]);
    selectedRowArray.forEach((row) => {
      const rowToDelete = row.toString();
      deleteRefDoc(rowToDelete);
    });
  };

  return (
    <>
      <div className="flex flex-col items-center bg-marveri-background text-[12px] text-marveri-white">
        <div className="w-[96%]">
          <h1 className="mt-[16px] w-full text-[34px] font-bold">
            {viewAllDocuments ? 'All Documents' : 'Missing Documents'}
          </h1>
          <div
            className={`${
              selectedRowArray.length > 0 ? 'justify-between' : 'justify-end'
            } mb-2 flex flex-row `}
          >
            {selectedRowArray.length > 0 && (
              <SimpleButton icon={TrashIcon} disabled={false} onClick={handleDeleteAll}>
                <span>
                  Remove {selectedRowArray.length} {viewAllDocuments ? '' : 'Missing'} Document
                  {selectedRowArray.length > 1 && 's'}
                </span>
              </SimpleButton>
            )}
            <div className=" flex flex-row gap-2">
              <Dropdown
                testId="change-view-button"
                title={viewAllDocuments ? 'View: All Documents' : 'View: Missing Documents Only'}
                isButtonDisabled={false}
                options={[
                  { title: 'Missing Documents Only', onClick: () => setViewAllDocuments(false) },
                  { title: 'All Documents', onClick: () => setViewAllDocuments(true) },
                ]}
              />
              <SimpleButton disabled={false} onClick={downloadTableAsExcel} icon={ExportIcon}>
                Export view to Excel
              </SimpleButton>
            </div>
            <Dialog
              open={isRefreshDialogOpen}
              onClose={closeDialog}
              className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50 "
            >
              <Dialog.Panel className="flex w-[554px] flex-col items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark p-4 text-marveri-white">
                <Dialog.Title className="w-full text-[25px] font-bold text-marveri-white">
                  <div className="flex w-full flex-row items-center justify-start">
                    <img className="ml-4" src={CautionBubble} />{' '}
                    <span className="m-2">Caution</span>
                  </div>
                </Dialog.Title>
                <div className="px-4 py-2">
                  <p>
                    If you continue, the system will reprocess the Missing Documents page from the
                    beginning! This will make the Missing Documents page inaccessible, potentially
                    for hours. Only continue if you would like to include any newly uploaded
                    documents and if you do not need access to any of the Missing Documents results
                    for the next several hours.
                  </p>
                  <br />
                  <p>
                    Additionally, note that the results the system provides after reprocessing may
                    not be identical to the current results. Consider exporting an Excel file prior
                    to continuing in order to save the current results.
                  </p>
                </div>
                <div className="m-6 flex w-full flex-row justify-end">
                  <button
                    onClick={closeDialog}
                    className="mx-2 h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      refreshDocs();
                      closeDialog();
                    }}
                    className="h-[38px] w-[100px] rounded border border-dark-border bg-marveri-gold text-container-dark hover:bg-marveri-light-gold"
                  >
                    Rerun
                  </button>
                </div>
              </Dialog.Panel>
            </Dialog>
          </div>
          {flags.missingDocColumnSelector && (
            <div className="my-4">
              {table.getAllColumns().map((column) => (
                <label key={column.id} className="mx-1">
                  <input
                    checked={column.getIsVisible()}
                    disabled={!column.getCanHide()}
                    onChange={column.getToggleVisibilityHandler()}
                    type="checkbox"
                  />
                  {column?.id}
                </label>
              ))}
            </div>
          )}
          <div className="w-full">
            <ScrollableDiv containerStyle="h-[calc(100vh-240px)] rounded-xl border border-[#525252] mb-2">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="sticky top-0 z-10 border-b border-light-border bg-[#2E2E2E] font-medium text-[#A9A9A9]">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          } select-none px-6 py-4`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <div className="flex flex-row p-2">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                            <span className="flex">
                              {header.column.getIsSorted() === 'asc' ? (
                                <img className="mr-1 align-middle" src={Arrow} />
                              ) : header.column.getIsSorted() === 'desc' ? (
                                <img className="mr-1 rotate-180 align-middle" src={Arrow} />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table
                    .getRowModel()
                    .rows.filter((row) => viewAllDocuments || row.original.status === 'Missing')
                    .map((row) => (
                      <tr
                        key={row.id}
                        className={`${
                          selectedRowIndex === row.index && 'bg-light-border'
                        } border-b border-light-border`}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="px-6 py-4 font-medium">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </ScrollableDiv>
          </div>
        </div>
      </div>
      {!!selectedFile && !!isSlideViewerOpen && (
        <SlideInPdfViewer
          title={selectedFile.displayName}
          originalTitle={selectedFile.name}
          selectedHighlight={selectedHighlight}
          closeSlideViewer={() => setIsSlideViewerOpen(false)}
          openPdfViewer={() => setIsViewerOpen(true)}
          setSelectedRowIndex={setSelectedRowIndex}
        />
      )}
      {!!selectedFile && !!isViewerOpen && !!selectedFileUrl ? (
        <PdfViewer
          title={selectedFile.displayName}
          originalTitle={selectedFile.name}
          fileUrl={selectedFileUrl}
          multipleDocuments={false}
          closePdfViewer={() => setIsViewerOpen(false)}
          highlightLocation={selectedHighlight}
          selectSecondFile={selectSecondFileByName}
          secondSelectedFile={secondSelectedFile}
          secondSelectedFileUrl={secondSelectedFileUrl}
        />
      ) : (
        <></>
      )}
    </>
  );
};
