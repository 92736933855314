import { useDraggable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

interface DraggableDataRoomFolderProps {
  draggableId: string;
  currentDroppableId: string;
  disabled: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const DraggableDataRoomFolder = ({
  draggableId,
  children,
  currentDroppableId,
  disabled,
  onMouseEnter,
  onMouseLeave,
}: PropsWithChildren<DraggableDataRoomFolderProps>) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `folder://${draggableId}`,
    data: {
      current: currentDroppableId,
    },
    disabled: disabled,
  });

  return (
    <button
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className="flex w-full items-center"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </button>
  );
};
