import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ClusterIcon from '@/assets/images/clusters.svg';
import RedlineIcon from '@/assets/images/redline-icon.svg';
import { Dropdown } from '@/components/Dropdown';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { useOverview } from '@/pages/overview/common/utils';
import { createAndSelectRedlineFiles } from '@/pages/overview/redline/utils';

interface RedlineButtonProps {
  filesAreProcessing: boolean;
}

export const RedlineButton = ({ filesAreProcessing }: RedlineButtonProps) => {
  const { matter } = useOverview();
  const [processing, setProcessing] = useState(false);
  const [isRedlineButtonDisabled, setIsRedlineButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const { checkedFiles } = useDataRoom();
  const onCluster = useCallback(async () => {
    if (checkedFiles.length === 0) return;
    setProcessing(true);
    try {
      const redlineName = `Redline ${matter.redlines.length + 1}`;
      await createAndSelectRedlineFiles(
        checkedFiles,
        matter.number,
        matter.client.number,
        redlineName,
      );
      navigate(`../redline/${redlineName}/clustering`);
    } finally {
      setProcessing(false);
    }
  }, [checkedFiles, matter.client.number, matter.number, matter.redlines.length, navigate]);

  const onRedline = useCallback(async () => {
    if (checkedFiles.length === 0) return;
    setProcessing(true);
    try {
      const redlineName = `Redline ${matter.redlines.length + 1}`;
      await createAndSelectRedlineFiles(
        checkedFiles,
        matter.number,
        matter.client.number,
        redlineName,
      );
      navigate(`../redline/${redlineName}/diff`);
    } finally {
      setProcessing(false);
    }
  }, [checkedFiles, matter.client.number, matter.number, matter.redlines.length, navigate]);

  const updateRedlineButtonStatus = useCallback(() => {
    setIsRedlineButtonDisabled(
      checkedFiles.length <= 1 ||
        checkedFiles.filter((file) => file.docType === 'Cap Table').length > 0,
    );
  }, [checkedFiles]);

  useEffect(() => {
    updateRedlineButtonStatus();
  }, [checkedFiles, updateRedlineButtonStatus]);

  return (
    <Dropdown
      processing={processing || filesAreProcessing}
      title="Redline"
      testId="redline-button"
      icon={RedlineIcon}
      options={[
        { title: 'Run a Redline', icon: RedlineIcon, onClick: onRedline },
        { title: 'Cluster', icon: ClusterIcon, onClick: onCluster },
      ]}
      isButtonDisabled={isRedlineButtonDisabled || filesAreProcessing}
      resizeAction={true}
    />
  );
};
