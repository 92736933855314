import { useCallback, useEffect, useState } from 'react';

import { useDataRoom } from '@/contexts/overview/dataroom/utils';

interface HoverTextProps {
  isButtonHovered: boolean;
  isDisabled?: boolean;
  redlineButton?: boolean;
  title: string;
  isLoading?: boolean;
}

export const HoverText = ({ isButtonHovered, isDisabled, title, isLoading }: HoverTextProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { checkedFiles, hasCheckedFiles } = useDataRoom();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  const hoverTextVisibility = useCallback(() => {
    if (!isDisabled && windowWidth > 1200) {
      return 'hidden';
    }
    if (isDisabled && isButtonHovered) {
      return 'block';
    }
    if (
      (hasCheckedFiles && windowWidth < 1200 && isButtonHovered) ||
      (!hasCheckedFiles && windowWidth > 1200 && isButtonHovered) ||
      (windowWidth < 1200 && !hasCheckedFiles && isButtonHovered)
    ) {
      return 'block';
    } else {
      return 'hidden';
    }
  }, [hasCheckedFiles, isButtonHovered, isDisabled, windowWidth]);

  const textDisplay = useCallback(() => {
    if (['Organize', 'Rename', 'Redline'].includes(title) && isLoading) {
      return `Processing. See Tool Status for Progress.`;
    }
    if (title === 'Redline' && checkedFiles?.length <= 1) {
      return 'Select files to use';
    }
    if (hasCheckedFiles) {
      return title;
    }
    if (!isDisabled) {
      return title;
    }
    return 'Select file(s) to use';
  }, [checkedFiles?.length, hasCheckedFiles, isDisabled, isLoading, title]);

  return (
    <div
      className={`${hoverTextVisibility()} absolute z-20 w-fit translate-y-[38px] whitespace-nowrap rounded-[5px] border border-container-hover bg-container-dark p-[4px] text-center text-xs font-normal text-marveri-white`}
    >
      {textDisplay()}
    </div>
  );
};
