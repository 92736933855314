import { PropsWithChildren } from 'react';
import { NavLink, Outlet } from 'react-router-dom';

interface ActiveSettingsTabProps {
  to: string;
}

export const ActiveSettingsTabLink = (props: PropsWithChildren<ActiveSettingsTabProps>) => {
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `font-semibold ${
          isActive ? 'text-marveri-gold' : 'hover:text-marveri-light-gold text-marveri-silver'
        }`
      }
      data-testid="active-settings-tab"
    >
      {props.children}
    </NavLink>
  );
};

export const Settings = () => {
  const settingsNavigation = [
    {
      tab: 'Profile Settings',
      path: 'profile',
    },
    {
      tab: 'Security & Privacy',
      path: 'security-privacy',
    },
  ];

  return (
    <div
      className="flex size-full justify-center bg-marveri-background"
      data-testid="settings-page"
    >
      <div className="mt-6 w-[70%]">
        <h1 className="text-[30px] font-bold text-marveri-white">Account Settings</h1>
        <div className="mt-8">
          <div className="flex gap-10 border-b-2 border-light-border pb-4">
            {settingsNavigation.map((settingNav) => {
              return (
                <ActiveSettingsTabLink key={settingNav.tab} to={`/settings/${settingNav.path}`}>
                  {settingNav.tab}
                </ActiveSettingsTabLink>
              );
            })}
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
