import { DataRoomClientFile } from '@server/modules/client-matter/common/types';

import {
  getPresignedPostAndUpload,
  renameDuplicateFiles,
  renameGivenExistingNamesForDuplicates,
} from '@/common/utils';
import { trpcClient } from '@/utils/trpc';

interface TempRedlineFile {
  name: string;
  url: string;
  extracted_text: string;
  average_file_embedding: number[];
}

export function renameDuplicateFilesForRedline(files: TempRedlineFile[]) {
  const usedFileNames = [] as string[];

  const newFiles = files.map((file) => {
    // TODO: there is probably a more efficient way to do this

    const fileName = file.name;
    const newName = renameGivenExistingNamesForDuplicates(fileName, usedFileNames);

    usedFileNames.push(newName);

    file.name = newName;

    return file;
  });
  return newFiles;
}

export async function createAndSelectRedlineFiles(
  redlineFilesUrls: DataRoomClientFile[],
  clientMatterNumber: number,
  clientNumber: number,
  redlineName: string,
) {
  const s3FilesUrls = redlineFilesUrls.map((file) => {
    return file.name;
  });

  await trpcClient.redline.createRedline.mutate({
    name: redlineName,
    matterNumber: clientMatterNumber,
    clientNumber: clientNumber,
  });

  await trpcClient.redline.addRedlineFilesFromDataRoom.mutate({
    fileNames: s3FilesUrls,
    redlineIdentifier: {
      name: redlineName,
      clientNumber: clientNumber,
      matterNumber: clientMatterNumber,
    },
    clientMatterNumber: clientMatterNumber,
    clientNumber: clientNumber,
  });
  await trpcClient.redline.generateClusters.mutate({
    name: redlineName,
    clientNumber: clientNumber,
    matterNumber: clientMatterNumber,
  });
}

export async function createAndUploadRedlineFiles(
  newRedlineFiles: File[],
  clientMatterNumber: number,
  clientNumber: number,
  redlineName: string,
  existingRedlineFileNames: string[] = [],
) {
  // Input validation (example: checking if arrays are not empty)
  if (newRedlineFiles.length === 0) {
    throw new Error('No files provided for upload.');
  }
  const files = renameDuplicateFiles(newRedlineFiles, existingRedlineFileNames);

  const redlineUploads = files.map((file) => {
    return getPresignedPostAndUpload(file, clientNumber, clientMatterNumber);
  });

  // Using Promise.allSettled to wait for all the uploads to either resolve or reject
  const uploadResults = await Promise.allSettled(redlineUploads);

  // Extract only the fulfilled promises and their values
  const successfulUploads = uploadResults.flatMap((result) =>
    result.status === 'fulfilled' ? [result.value] : [],
  );

  // Proceed with mutation only if there is at least one successful upload
  if (successfulUploads.length > 0) {
    await trpcClient.redline.createRedline.mutate({
      name: redlineName,
      matterNumber: clientMatterNumber,
      clientNumber: clientNumber,
    });

    const processedUploads = successfulUploads.map((upload) => {
      return {
        name: upload.name,
        url: upload.url,
        extracted_text: '',
        average_file_embedding: [],
      };
    });

    await trpcClient.redline.addRedlineFiles.mutate({
      files: processedUploads,
      redline_identifier: {
        name: redlineName,
        clientNumber: clientNumber,
        matterNumber: clientMatterNumber,
      },
    });
    await trpcClient.redline.generateClustersTask.mutate({
      matterNumber: clientMatterNumber,
      name: redlineName,
      clientNumber,
    });
  }
  // If there are any rejections, we throw to propagate the error
  const hasRejections = uploadResults.some((result) => result.status === 'rejected');
  if (hasRejections) {
    const errors = uploadResults
      .filter((result): result is PromiseRejectedResult => result.status === 'rejected')
      .map((result) => result.reason);
    throw new AggregateError(errors, 'One or more file uploads failed.');
  }
}

export async function deleteRedline(
  clientMatterNumber: number,
  clientNumber: number,
  redlineName: string,
) {
  await trpcClient.redline.deleteRedline.mutate({
    name: redlineName,
    matterNumber: clientMatterNumber,
    clientNumber: clientNumber,
  });
}

export async function changeTemplate({
  matterNumber,
  clusterName,
  clientNumber,
  redlineName,
  newTemplateFile,
}: {
  matterNumber: number;
  clientNumber: number;
  redlineName: string;
  clusterName: string;
  newTemplateFile: string;
}) {
  await trpcClient.redline.setNewTemplateForCluster.mutate({
    clusterIdentifier: {
      redlineIdentifier: {
        clientNumber,
        matterNumber,
        name: redlineName,
      },
      clusterName,
    },
    newTemplateFileName: newTemplateFile,
  });
}
