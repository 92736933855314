import { Dialog } from '@headlessui/react';
import { useState } from 'react';

import ArrowIcon from '@/assets/images/arrow-icon.svg';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants';
import PrivacyPolicyDoc from '@/docs/Marveri Privacy Policy.pdf';
import TermsOfUseDoc from '@/docs/User Terms of Service.pdf';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { SimplePdfViewer } from '@/pages/pdf-viewer/SimplePdfViewer';
import { AuthenticatorModal } from '@/pages/settings/security/AuthenticatorModal';
import { trpcReact } from '@/utils/trpc';

export const Security = () => {
  const [isAuthenticatorModalOpen, setIsAuthenticatorModalOpen] = useState(false);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);
  const user = trpcReact.user.getCurrentUser.useQuery().data;

  const handlePrivacyOptionClick = (title: string, file: string) => {
    setSelectedPdf(file);
    setPdfTitle(title);
    setIsPdfViewerOpen(true);
  };

  const handleUpdatePassword = async () => {
    const data = {
      client_id: AUTH0_CLIENT_ID,
      email: user?.email,
      connection: 'Username-Password-Authentication',
    };
    const response = await fetch(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });

    if (response.ok) {
      setIsPasswordResetModalOpen(true);
    }
  };

  return (
    <div className="mt-10" data-testid="security-page">
      <div className="border-b-2 border-light-border pb-4">
        <h1 className="text-[25px] font-bold text-marveri-white">Security</h1>
      </div>
      <div className="flex justify-between border-b-2 border-light-border p-4 hover:bg-container-hover">
        <span className="font-bold text-marveri-light-silver">Password</span>
        <span className="cursor-pointer text-marveri-white" onClick={handleUpdatePassword}>
          Send Password Reset E-mail
        </span>
      </div>
      <div className="flex justify-between border-b-2 border-light-border p-4 hover:bg-container-hover">
        <span className="font-bold text-marveri-light-silver">Two-Factor Authentification</span>
        <span
          className="flex cursor-pointer gap-2 text-marveri-white"
          onClick={() => setIsAuthenticatorModalOpen(true)}
        >
          Authenticator App
          <span className="flex -rotate-90 items-center justify-center text-marveri-white">
            <MarveriIcon icon={ArrowIcon} iconStyle="" iconType="other" />
          </span>
        </span>
      </div>
      <div className="mt-10 border-b-2 border-light-border pb-4">
        <h1 className="text-[25px] font-bold text-marveri-white">Privacy</h1>
      </div>
      <div
        className="flex cursor-pointer justify-between border-b-2 border-light-border p-4 font-bold hover:bg-container-hover"
        onClick={() => handlePrivacyOptionClick('Marveri Privacy Policy', PrivacyPolicyDoc)}
      >
        <span className="text-marveri-light-silver">Privacy Policies</span>
        <span className="flex -rotate-90 cursor-pointer items-center justify-center text-marveri-white">
          <MarveriIcon icon={ArrowIcon} iconStyle="" iconType="other" />
        </span>
      </div>
      <div
        className="flex cursor-pointer justify-between border-b-2 border-light-border p-4 font-bold hover:bg-container-hover"
        onClick={() => handlePrivacyOptionClick('Marveri Terms of Use', TermsOfUseDoc)}
      >
        <span className="text-marveri-light-silver">Terms of Use</span>
        <span className="flex -rotate-90 items-center justify-center text-marveri-white">
          <MarveriIcon icon={ArrowIcon} iconStyle="" iconType="other" />
        </span>
      </div>
      <AuthenticatorModal
        isAuthenticatorModalOpen={isAuthenticatorModalOpen}
        setIsAuthenticatorModalOpen={setIsAuthenticatorModalOpen}
      />
      {isPdfViewerOpen && (
        <SimplePdfViewer
          title={pdfTitle}
          fileUrl={selectedPdf}
          closePdfViewer={() => setIsPdfViewerOpen(false)}
        />
      )}
      {isPasswordResetModalOpen && (
        <Dialog
          open={isPasswordResetModalOpen}
          onClose={() => setIsPasswordResetModalOpen(false)}
          className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
        >
          <Dialog.Panel className="flex h-fit w-[454px] flex-col items-center justify-center rounded-[5px] border-2 border-light-border bg-container-dark p-4">
            <Dialog.Title className="w-[355px] text-center text-[25px] font-bold text-marveri-white">
              Reset Password Link Sent
            </Dialog.Title>
            <p className="w-[355px] pb-2 text-center text-[15px] text-marveri-white">
              You can reset your password by following the secure link in the e-mail recently sent
              to you.
            </p>
            <br />
            <button
              className="h-[38px] w-[100px] rounded border border-dark-border bg-marveri-gold text-marveri-white hover:bg-marveri-light-gold"
              onClick={() => setIsPasswordResetModalOpen(false)}
            >
              OK
            </button>
          </Dialog.Panel>
        </Dialog>
      )}
    </div>
  );
};
