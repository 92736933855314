import { createContext, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NEW_DILIGENCE_WIZARD_ROUTES } from '@/contexts/create-new-diligence-wizard/constants';
import { NewDiligenceData } from '@/contexts/create-new-diligence-wizard/types';
import { createNewDiligence } from '@/contexts/create-new-diligence-wizard/utils';

const numRoutes = NEW_DILIGENCE_WIZARD_ROUTES.length;

interface NewDiligenceWizardContextType {
  newDiligenceData: NewDiligenceData;
  stepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  moveToNextStep: () => void;
  moveToPrevStep: () => void;
  updateNewDiligenceData: (updatedData: NewDiligenceData) => void;
  finishDataRoomUpload: () => Promise<void>;
  uploadProcessing: boolean;
  setUploadProcessing: (val: boolean) => void;
}

export const NewDiligenceWizardContext = createContext<NewDiligenceWizardContextType | undefined>(
  undefined,
);

export function NewDiligenceWizardProvider({ children }: { children: ReactNode }) {
  const [stepIndex, setStepIndex] = useState(0);
  const [uploadProcessing, setUploadProcessing] = useState(false);

  const isFirstStep = stepIndex === 0;
  const isLastStep = stepIndex === numRoutes - 1;

  const [newDiligenceData, setNewDiligenceData] = useState<NewDiligenceData>({});

  const updateNewDiligenceData = (updatedData: NewDiligenceData) =>
    setNewDiligenceData((prevData) => {
      return { ...prevData, ...updatedData };
    });

  const navigate = useNavigate();

  const moveToNextStep = () => {
    if (stepIndex == numRoutes - 1) {
      return;
    }
    setStepIndex((index) => {
      return index + 1;
    });
    navigate(NEW_DILIGENCE_WIZARD_ROUTES[stepIndex + 1]);
  };

  const moveToPrevStep = () => {
    setStepIndex((index) => {
      if (index === 0) return index;
      return index - 1;
    });
    navigate(-1);
  };

  const finishDataRoomUpload = async () => {
    await createNewDiligence(newDiligenceData);
    setTimeout(() => {
      if (newDiligenceData.client) {
        setUploadProcessing(false);
        navigate(`/${newDiligenceData.client.number}/${newDiligenceData.number}/dataroom`);
      }
    }, 500);
  };

  return (
    <NewDiligenceWizardContext.Provider
      value={{
        stepIndex,
        isFirstStep,
        isLastStep,
        moveToNextStep,
        moveToPrevStep,
        newDiligenceData,
        updateNewDiligenceData,
        finishDataRoomUpload,
        uploadProcessing,
        setUploadProcessing,
      }}
    >
      {children}
    </NewDiligenceWizardContext.Provider>
  );
}
