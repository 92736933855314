import { HighlightArea } from '@react-pdf-viewer/highlight';

import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';

interface FinancingRightsPanelProps {
  hoveredHighlight?: HighlightArea | null;
  handleJumpToHighlight?: (highlights: number[]) => void;
  handleReferenceMouseOver?: (highlights: number[]) => void;
}

export const FinancingRightsPanel = ({
  hoveredHighlight,
  handleJumpToHighlight,
  handleReferenceMouseOver,
}: FinancingRightsPanelProps) => {
  const { selectedFileDetails } = useDataRoom();

  const displayedVetoData = selectedFileDetails?.vetoData.clausesRevised;

  const handleClauseClick = (clause: string) => {
    const parsedLocations = JSON.parse(clause);
    if (handleJumpToHighlight) {
      handleJumpToHighlight(parsedLocations.locations);
    }
  };

  const handleMouseOver = (clause: string) => {
    if (handleReferenceMouseOver) {
      if (clause === '') {
        handleReferenceMouseOver([]);
        return;
      } else {
        const parsedLocations = JSON.parse(clause);
        handleReferenceMouseOver(parsedLocations.locations);
      }
    }
  };

  const handleHighlightHover = (clause: string) => {
    const parsedLocations = JSON.parse(clause);
    if (
      hoveredHighlight?.pageIndex === parsedLocations.locations[0] &&
      hoveredHighlight?.top === parsedLocations.locations[2]
    ) {
      return 'bg-[#0057D6]';
    }
  };

  return (
    <div
      className="relative flex h-full w-[400px] shrink-0 flex-col bg-container-dark text-marveri-white"
      data-testid="veto-debug-panel"
    >
      <section
        className="flex flex-col  justify-center border-b border-b-light-border px-[14px]"
        data-testid="veto-debug-overview"
      >
        <h1 className="my-2 text-[14px] font-medium text-marveri-light-silver">Overview</h1>
        <div className="my-2 text-[14px] font-normal">
          <p data-testid="veto-debug-overview-title">{displayedVetoData?.overall_title}</p>
        </div>
      </section>
      <section
        className="flex flex-col  justify-center border-b border-b-light-border px-[14px]"
        data-testid="veto-debug-decription"
      >
        <h1 className="my-2 text-[14px] font-medium text-marveri-light-silver">
          Description of Rights and Obligations
        </h1>
        <div className="my-2 text-[14px] font-normal">
          <p data-testid="veto-debug-explanation">{displayedVetoData?.overall_explanation}</p>
        </div>
      </section>
      <section
        className="flex flex-col  justify-center px-[14px]"
        data-testid="veto-debug-provisions"
      >
        <h1 className="my-2 text-[14px] font-medium text-marveri-light-silver">
          Selected Provisions
        </h1>
        <ScrollableDiv containerStyle="h-[calc(100vh-360px)]">
          {displayedVetoData?.rights_clauses.map((clause, index) => {
            return (
              <div
                key={index}
                className={`my-2 border-b border-b-light-border pb-2 text-[14px] font-normal `}
                onClick={() => handleClauseClick(clause.location)}
                data-testid={`veto-debug-clause${index}`}
              >
                <span
                  className={`cursor-pointer rounded-lg p-2 hover:bg-container-light-gray ${handleHighlightHover(clause.location)}`}
                  onMouseOver={() => handleMouseOver(clause.location)}
                  onMouseOut={() => handleMouseOver('')}
                >
                  {clause.brief_description}:
                </span>
                <p className="p-2">{`"${clause.quotation}"`}</p>
              </div>
            );
          })}
        </ScrollableDiv>
      </section>
    </div>
  );
};
