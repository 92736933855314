import { Popover } from '@headlessui/react';
import { useEffect, useMemo, useState } from 'react';

import ArrowIcon from '@/assets/images/arrow-white.svg';
import GreenCheck from '@/assets/images/greencheck.svg';
import CloseIcon from '@/assets/images/x-icon.svg';
import { ClientMatterWithDetails } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';

interface ToolStatusProps {
  isPanelCollapsed: boolean;
  matter: ClientMatterWithDetails | undefined;
}

export const ToolStatus = ({ isPanelCollapsed, matter }: ToolStatusProps) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const filesAreProcessing = useMemo(() => {
    return !matter?.number || matter?.fileUploadPercentComplete != 100;
  }, [matter?.fileUploadPercentComplete, matter?.number]);

  const signaturesAreProcessing = useMemo(() => {
    return (
      matter?.dataRoomFiles.length === 0 ||
      matter?.dataRoomFiles.some((file) => file.missingSignature === null)
    );
  }, [matter?.dataRoomFiles]);

  const referencesAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingMissingDocs;
  }, [matter]);

  const financingsAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingVetoData;
  }, [matter]);

  const clausesAreProcessing = useMemo(() => {
    return !matter?.finishedProcessingMAClausesData;
  }, [matter]);

  const toolStatuses = useMemo(() => {
    if (!matter || !matter.createdAt) {
      return [];
    }
    /*  We estimate that PROCESS_DATA will take 3 min for every 10 files,
     **  MISSING_SIGNATURES will take 15 min for every 10 files,
     **  and MISSING_REFERENCES will take 30 min and 1 extra for each file.
     */
    return [
      {
        name: 'Redlining',
        description: filesAreProcessing
          ? 'Preparing documents for redline'
          : 'Any set of documents can now be clustered and redlined',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() +
            1000 * 60 * 3 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
        ),
        complete: !filesAreProcessing,
      },
      {
        name: filesAreProcessing ? 'Summarizing' : 'Summaries',
        description: filesAreProcessing
          ? 'Summarizing key information from every document'
          : 'Every document has been summarized',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() +
            1000 * 60 * 3 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
        ),
        complete: !filesAreProcessing,
      },
      {
        name: 'Renaming',
        description: filesAreProcessing
          ? 'Analyzing every document for optimal file names'
          : 'Every document can now be intelligently renamed',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() +
            1000 * 60 * 3 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
        ),
        complete: !filesAreProcessing,
      },
      {
        name: 'Organizing',
        description: filesAreProcessing
          ? 'Determining the best folder for every document'
          : 'Every document can now be intelligently organized',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() +
            1000 * 60 * 3 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
        ),
        complete: !filesAreProcessing,
      },
      {
        name: signaturesAreProcessing
          ? 'Detecting Missing Signatures'
          : 'Missing Signatures Review',
        description: signaturesAreProcessing
          ? 'Reviewing each document for who needs to sign and confirming whether each signature is present'
          : 'Every document has been reviewed for potential missing signatures that may need to be confirmed',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() +
            1000 * 60 * 15 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
        ),
        complete: !signaturesAreProcessing,
      },
      {
        name: financingsAreProcessing
          ? 'Reviewing for Financing Rights'
          : 'Financing Rights Review',
        description: financingsAreProcessing
          ? 'Reviewing every document for rights that may be triggered in a preferred stock financing'
          : 'A list of rights that may be triggered in a preferred stock financing has been compiled',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() +
            1000 * 60 * 15 * Math.ceil(matter?.dataRoomFiles.length / 10.0),
        ),
        complete: !financingsAreProcessing,
      },
      {
        name: clausesAreProcessing ? 'Reviewing for Contract Clauses' : 'Contract Clauses Review',
        description: clausesAreProcessing
          ? 'Reviewing all agreements to identify clauses that may be implicated in a merger or other corporate acquisition'
          : 'Every contract has been reviewed and relevant clauses have been identified',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() + 1000 * 60 * (30 + matter?.dataRoomFiles.length),
        ),
        complete: !clausesAreProcessing,
      },
      {
        name: 'Linking Documents',
        description: referencesAreProcessing
          ? 'Reviewing every document for references to other documents and connecting these documents together'
          : 'Documents referencing each other have been linked together',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() + 1000 * 60 * (30 + matter?.dataRoomFiles.length),
        ),
        complete: !referencesAreProcessing,
      },
      {
        name: 'Searching For Missing Documents',
        description: referencesAreProcessing
          ? 'Compiling list of every reference to a document that is not matched to another document'
          : 'A list of missing documents has been compiled and can be exported to an Excel spreadsheet',
        startTime: new Date(matter?.createdAt),
        finishTime: new Date(
          new Date(matter?.createdAt).getTime() + 1000 * 60 * (30 + matter?.dataRoomFiles.length),
        ),
        complete: !referencesAreProcessing,
      },
    ];
  }, [
    clausesAreProcessing,
    filesAreProcessing,
    financingsAreProcessing,
    matter,
    referencesAreProcessing,
    signaturesAreProcessing,
  ]);

  return (
    <Popover className="group w-full" data-testid="tool-status">
      <Popover.Button className="w-[219px] shadow-none focus:outline-none">
        <div
          className="flex h-[36px] w-full cursor-pointer items-center justify-between gap-4 rounded-md border border-light-border pl-[16px] text-[14px] font-bold text-marveri-white hover:bg-container-hover"
          data-testid="tool-status-button"
        >
          <span className="flex flex-row">
            {referencesAreProcessing || signaturesAreProcessing || filesAreProcessing ? (
              <span className="w-[32px]">
                <LoadingSpinner />
              </span>
            ) : (
              <span className="w-[32px]">
                <img src={GreenCheck} className="mt-[2px] size-[16px]" />
              </span>
            )}
            <span className="truncate">
              {referencesAreProcessing || signaturesAreProcessing || filesAreProcessing
                ? 'Processing Tools'
                : 'All tools ready'}
            </span>
          </span>
          <img
            src={ArrowIcon}
            className="-rotate-90 p-4 group-data-[headlessui-state=open]:rotate-90"
          />
        </div>
      </Popover.Button>
      <Popover.Panel
        className={`absolute top-[205px] ${
          isPanelCollapsed ? 'left-[57px]' : 'left-[225px]'
        } z-10 w-[440px] rounded-md border border-light-border bg-container-hover text-white`}
        data-testid="tool-status-panel"
      >
        {({ close }) => (
          <>
            <div className="border-b border-light-border py-4 pl-4 pr-2">
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <div className="text-[16px]">Tool Status</div>
                  {filesAreProcessing || signaturesAreProcessing || referencesAreProcessing ? (
                    <div className="pt-1 text-[12px] text-[#868686]">
                      We&apos;ll email you when processing has completed
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <MarveriIcon
                  icon={CloseIcon}
                  onClick={() => close()}
                  iconStyle="m-1 mr-3"
                  iconType="other"
                />
              </div>
            </div>
            <ScrollableDiv containerStyle="pr-2 h-[calc(99vh-285px)]">
              {toolStatuses.map((tool) => {
                let percentage = Math.min(
                  ((date.getTime() - tool.startTime.getTime()) /
                    (tool.finishTime.getTime() - tool.startTime.getTime())) *
                    100.0,
                  95,
                );
                let barColor = 'bg-marveri-gold';
                let title = tool.name;
                if (tool.complete) {
                  percentage = 100;
                  barColor = 'bg-[#5CAD5E]';
                  title = title + ' Ready';
                } else {
                  title = title + '...';
                }
                return (
                  <div key={tool.name} className="flex flex-row p-4">
                    <div className="min-w-10">
                      {tool.complete ? <img src={GreenCheck} className="w-6"></img> : <></>}
                    </div>
                    <div className="mb-1 flex w-full flex-col text-[12px]">
                      <div>{title}</div>
                      <div className="my-2 text-[#868686]">{tool.description}</div>
                      <div className="h-2.5 w-full rounded-full bg-light-border">
                        <div
                          className={`h-2.5 rounded-full ${barColor}`}
                          style={{ width: percentage + '%' }}
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </ScrollableDiv>
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
};
