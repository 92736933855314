import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@/assets/images/delete-icon.png';
import EllipsisIcon from '@/assets/images/ellipsis.png';
import { DeleteDiligence } from '@/pages/home/content/DeleteDiligenceModal';

export interface DiligenceCardProps {
  matterItem: {
    clientName: string | null;
    clientNumber: number;
    number: number;
    numberOfFiles: number;
  };
}

export const DiligenceCard = ({ matterItem }: DiligenceCardProps) => {
  const [isCardHovered, setIsCardHovered] = useState<boolean>(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleMouseOut = () => {
    setIsCardHovered(false);
    setIsDeleteVisible(false);
  };

  const handleMatterClicked = () => {
    localStorage.setItem('clientName', matterItem.clientName || '');
    navigate(`/${matterItem.clientNumber}/${matterItem.number}/dataroom`);
  };

  return (
    <div
      className="relative flex h-[66px] rounded-[5px] border border-light-border bg-container-dark pl-[16px] pt-[6px] text-marveri-white hover:bg-container-hover"
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={handleMouseOut}
      data-testid="diligence-card"
    >
      <div
        className="z-[1] flex size-full cursor-pointer flex-col "
        onClick={handleMatterClicked}
        data-testid="matter-item"
      >
        <div
          className="w-[95%] break-words text-[14px] font-semibold leading-[30px]"
          data-testid="matter-item-title"
        >
          <span>{matterItem.clientName}</span>
        </div>
        <div
          className="flex flex-col text-[12px] text-marveri-light-silver"
          data-testid="matter-item-total-files"
        >
          <span>
            {matterItem.numberOfFiles} Document{matterItem.numberOfFiles > 1 && 's'}
          </span>
        </div>
      </div>
      <div
        className={`${
          isCardHovered ? 'block' : 'hidden'
        } absolute right-0 top-0 z-[2] m-2 cursor-pointer rounded-[5px] border-[1.5px] border-marveri-muted-silver hover:border-dark-border hover:bg-container-darkest`}
        onClick={() => setIsDeleteVisible((current) => !current)}
        data-testid="elipsis-icon"
      >
        <img src={EllipsisIcon} className="m-2 h-[20px]" />
      </div>
      <div
        onClick={() => setIsDeleteModalOpen(true)}
        className={`${
          isDeleteVisible ? 'block' : 'hidden'
        } absolute right-0 top-0 z-[3] m-2 flex h-[35px] w-[130px] -translate-y-9 translate-x-[93px] cursor-pointer items-center rounded-[5px] border-2 border-dark-border bg-container-darkest text-[14px] hover:bg-container-hover`}
        data-testid="delete-icon"
      >
        <img src={DeleteIcon} className="m-2 h-[16px]" />
        Delete
      </div>
      <DeleteDiligence
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setIsDeleteVisible={setIsDeleteVisible}
        matterItem={matterItem}
      />
    </div>
  );
};
