import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { DataRoomClientFile } from '@/common/types';
import { organizeDocumentsByPath, useOverview } from '@/pages/overview/common/utils';
import { FilesByPath } from '@/pages/overview/dataroom/content/common/types';
import { ClausesRevisedObject } from '@/pages/overview/financing-rights/common/utils';
import { FileDetails } from '@/pages/pdf-viewer/PdfViewer';
import { trpcReact } from '@/utils/trpc';

interface IDataRoomContext {
  selectedFile?: DataRoomClientFile;
  selectedFileUrl?: string;
  secondSelectedFile?: DataRoomClientFile;
  secondSelectedFileUrl?: string;
  setSelectedFile: (fileName: string) => void;
  selectedRenameFile?: DataRoomClientFile;
  setSelectedRenameFile: (file: DataRoomClientFile) => void;
  selectedFolder?: string;
  setSelectedFolder: (folderName: string) => void;
  updateFolderSelection: (path: string, toRemove: boolean) => void;
  updateAllFolderSelection: () => void;
  setSelectedFoldersArray: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFoldersArray: string[];
  setDraggedContent: (dragContent: string) => void;
  setIsCheckAllActive: React.Dispatch<React.SetStateAction<boolean>>;
  isViewerOpen: boolean;
  setIsViewerOpen: (val: boolean) => void;
  isCheckAllActive: boolean;
  checkedFiles: DataRoomClientFile[];
  setCheckedFiles: React.Dispatch<React.SetStateAction<DataRoomClientFile[]>>;
  hasCheckedFiles: boolean;
  goToNextDocument: () => void;
  goToPreviousDocument: () => void;
  paths: string[];
  dataRoomFiles: DataRoomClientFile[];
  combinedDocumentArray: DataRoomClientFile[];
  isFolderOpenByPath: Record<string, boolean>;
  setIsFolderOpenByPath: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  isFolderChecked: Record<string, boolean>;
  setIsFolderChecked: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  changeFolderCheckStatus: (checkStatus: boolean) => void;
  initialClickIndex: number;
  setInitialClickIndex: (val: number) => void;
  renameFileIndex: number | undefined;
  setRenameFileIndex: (val: number | undefined) => void;
  isFileRenameActive: boolean;
  updateRenameFormStatus: (val: boolean) => void;
  updateSignature: (fileName: string, truthyValue: boolean) => void;
  selectedFileDetails?: FileDetails;
  secondSelectedFileDetails?: FileDetails;
  selectSecondFileByName: (name: string) => void;
  updateSubFolders: (
    folderPath: string,
    isChecked: boolean,
    folderStatus: {
      [folder: string]: boolean;
    },
  ) => void;
}

export const DataRoomContext = createContext<IDataRoomContext | undefined>(undefined);

export function DataRoomContextProvider({ children }: { children: ReactNode }) {
  const {
    matter: {
      number: matterNumber,
      client: { number: clientNumber },
      dataRoomFiles: allFiles,
      captableFile,
      folderConfig,
    },
  } = useOverview();

  const dataRoomFiles = useMemo(() => {
    if (captableFile) {
      return [
        ...allFiles,
        {
          name: '/!Cap Table/' + captableFile.name,
          url: captableFile.url,
          displayName: captableFile.name,
          pdfUrl: captableFile.url,
          docType: 'Cap Table',
          summary: 'Cap Table',
          practiceArea: 'Cap Table',
          signaturePages: [],
          previousDisplayNames: [],
          path: '/!Cap Table',
          originalPathname: '/!Cap Table/' + captableFile.name,
          bullets: [],
          subject: null,
          date: null,
          company: null,
          missingSignature: false,
          mentions: [],
          mentionedBy: [],
          otherDocumentData: [],
          otherSignatureData: [],
          coiData: [],
          vetoData: [],
          maClausesData: [],
          extracted_text: '',
          average_file_embedding: [],
        },
      ];
    } else {
      return allFiles;
    }
  }, [captableFile, allFiles]);

  const [selectedFile, setSelectedFile] = useState<DataRoomClientFile | undefined>();
  const [secondSelectedFile, setSecondSelectedFile] = useState<DataRoomClientFile | undefined>();
  const [selectedRenameFile, setSelectedRenameFile] = useState<DataRoomClientFile | undefined>();
  const [selectedFolder, setSelectedFolder] = useState('');
  const [selectedFoldersArray, setSelectedFoldersArray] = useState<string[]>([]);
  const [isCheckAllActive, setIsCheckAllActive] = useState(false);
  const [checkedFiles, setCheckedFiles] = useState<DataRoomClientFile[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [initialClickIndex, setInitialClickIndex] = useState<number>(0);
  const [isFileRenameActive, setIsFileRenameActive] = useState<boolean>(false);

  const [renameFileIndex, setRenameFileIndex] = useState<number>();
  const paths: string[] = useMemo(() => {
    return Array.from(new Set(dataRoomFiles.map((file) => file.path)));
  }, [dataRoomFiles]);
  const [isFolderOpenByPath, setIsFolderOpenByPath] = useState({} as Record<string, boolean>);
  const initialFoldersChecked = paths.reduce(
    (checkedFolders, path) => {
      checkedFolders[path] = false;
      return checkedFolders;
    },
    {} as Record<string, boolean>,
  );
  const [isFolderChecked, setIsFolderChecked] =
    useState<Record<string, boolean>>(initialFoldersChecked);

  useEffect(() => {
    const isOpenMap = {} as Record<string, boolean>;
    const storedOpenMap = localStorage.getItem('folderOpenMap');
    paths.forEach((path) => {
      if (path === '/~Trash') {
        isOpenMap[path] = false;
      } else {
        isOpenMap[path] = true;
      }
    });
    if (storedOpenMap) {
      const parsedStoredData: Record<string, boolean> = storedOpenMap
        ? JSON.parse(storedOpenMap)
        : {};
      parsedStoredData['/'] = true;
      setIsFolderOpenByPath(parsedStoredData);
    } else {
      setIsFolderOpenByPath(isOpenMap);
      const storedOpenMapJson = JSON.stringify(isOpenMap);
      localStorage.setItem('folderOpenMap', storedOpenMapJson);
    }
  }, [paths]);

  const selectedFileUrl: string | undefined = trpcReact.aws.getPresignedGets.useQuery(
    {
      matterNumber,
      clientNumber,
      // The query needs an initial value, but it won't be triggered until the selected file is set or changes
      files: [
        {
          name: selectedFile?.name || '',
          // Download the pdf version of the file
          pdf: true,
        },
      ],
    },
    {
      enabled: !!selectedFile && selectedFile.summary !== 'Cap Table',
    },
  ).data?.[0].url;

  const secondSelectedFileUrl: string | undefined = trpcReact.aws.getPresignedGets.useQuery(
    {
      matterNumber,
      clientNumber,
      // The query needs an initial value, but it won't be triggered until the selected file is set or changes
      files: [
        {
          name: secondSelectedFile?.name || '',
          // Download the pdf version of the file
          pdf: true,
        },
      ],
    },
    {
      enabled: !!secondSelectedFile && secondSelectedFile.summary !== 'Cap Table',
    },
  ).data?.[0].url;

  //Get all documents organized by paths
  const pathOrganizedDocuments = useMemo(() => {
    return organizeDocumentsByPath(dataRoomFiles);
  }, [dataRoomFiles]);

  const sortedDocumentKeys = useMemo(() => {
    return Object.keys(pathOrganizedDocuments).sort();
  }, [pathOrganizedDocuments]);

  const combinedDocumentArray = useMemo(() => {
    const sortedDocumentArray: FilesByPath = {};
    //Sort the original array based on the sorted keys
    sortedDocumentKeys.forEach((key) => {
      sortedDocumentArray[key] = pathOrganizedDocuments[key].sort((a, b) => {
        const aKey = Object.keys(a)[0];
        const bKey = Object.keys(b)[0];
        return aKey.localeCompare(bKey);
      });
    });

    //Sort them alphabetically within their respective array
    const sortedArrayByName = Object.values(sortedDocumentArray).map((file) =>
      file.sort((a, b) => {
        if (a.displayName === b.displayName) {
          return a.name.localeCompare(b.name);
        }
        return a.displayName.localeCompare(b.displayName);
      }),
    );

    //Create a combined array to match displayed value of the data room. Allowing seemlees PDF Viewer navigation
    return Object.values(sortedArrayByName).flat();
  }, [pathOrganizedDocuments, sortedDocumentKeys]);

  const getCurrentFileIndex = useCallback(() => {
    const currentFileIndex = combinedDocumentArray.findIndex(
      (dataRoomFile) => dataRoomFile.name === selectedFile?.name,
    );
    return currentFileIndex;
  }, [combinedDocumentArray, selectedFile?.name]);

  const onSelectFile = useCallback(
    (fileName: string) => {
      const foundFile = dataRoomFiles.find((file) => file.name === fileName);
      for (const folder of Object.keys(pathOrganizedDocuments)) {
        const fileList = pathOrganizedDocuments[folder];
        for (const file of fileList) {
          if (file === foundFile) {
            if (isFolderOpenByPath[folder] === false) {
              isFolderOpenByPath[folder] = true;
            }
          }
        }
      }
      setSecondSelectedFile(undefined);
      setSelectedFile(foundFile);
      setSelectedFolder('');
    },
    [dataRoomFiles, isFolderOpenByPath, pathOrganizedDocuments],
  );

  const setDraggedContent = (dragContent: string) => {
    if (dragContent === '') {
      setSelectedFolder('');
      return;
    }
    const lastFolder = dragContent.lastIndexOf('/');
    const folderName = dragContent.substring(lastFolder + 1);
    setSelectedFolder(folderName);
  };

  const goToNextDocument = useCallback(() => {
    localStorage.setItem('current-page', '0');
    let nextFileIndex: number;
    if (getCurrentFileIndex === null) nextFileIndex = 0;
    nextFileIndex = getCurrentFileIndex() + 1;
    if (nextFileIndex > combinedDocumentArray.length - 1) nextFileIndex = 0;
    const nextFile = combinedDocumentArray[nextFileIndex];
    onSelectFile(nextFile.name);
  }, [combinedDocumentArray, getCurrentFileIndex, onSelectFile]);

  const goToPreviousDocument = useCallback(() => {
    localStorage.setItem('current-page', '0');
    let prevFileIndex: number;
    if (getCurrentFileIndex === null) prevFileIndex = combinedDocumentArray.length - 1;
    prevFileIndex = getCurrentFileIndex() - 1;
    if (prevFileIndex < 0) prevFileIndex = combinedDocumentArray.length - 1;
    const prevFile = combinedDocumentArray[prevFileIndex];
    onSelectFile(prevFile.name);
  }, [combinedDocumentArray, getCurrentFileIndex, onSelectFile]);

  const hasCheckedFiles = useMemo(() => {
    return checkedFiles.length > 0;
  }, [checkedFiles.length]);

  const updateFolderSelection = useCallback((path: string, toRemove: boolean) => {
    setSelectedFoldersArray((prevArray) => {
      const isSelected = prevArray.includes(path);
      if (toRemove && isSelected) {
        const newArray = prevArray.filter((item) => item !== path);
        if (newArray.length === 0) {
          setSelectedFolder('');
        }
        return newArray;
      } else if (!toRemove && !isSelected) {
        return [...prevArray, path];
      }
      return prevArray;
    });
  }, []);

  const updateAllFolderSelection = () => {
    selectedFoldersArray.forEach((folder) => {
      updateFolderSelection(folder, true);
    });
  };

  const signatureToUpdate = trpcReact.dataRoom.updateMissingSignature.useMutation();
  const updateSignature = (fileName: string, signatureStatus: boolean) => {
    if (checkedFiles.length > 0) {
      checkedFiles.forEach((file) => {
        signatureToUpdate.mutate({
          clientNumber: clientNumber,
          clientMatterNumber: matterNumber,
          fileName: file.name,
          missingSignature: signatureStatus,
        });
      });
    }

    signatureToUpdate.mutate({
      clientNumber: clientNumber,
      clientMatterNumber: matterNumber,
      fileName: fileName,
      missingSignature: signatureStatus,
    });
  };

  const updateRenameFormStatus = useCallback((newValue: boolean) => {
    setIsFileRenameActive(newValue);
  }, []);

  const parseFileDetails = useCallback((file: DataRoomClientFile) => {
    return {
      title: file?.displayName || '',
      type: file.docType || '',
      effective_date: file?.date?.toString() || 'N/A',
      summary: file?.summary || '',
      vetoData: {
        source: {
          displayName: file?.displayName || '',
          name: file?.name || '',
        },
        clausesFound: file.vetoData[0]?.clausesFound || '',
        clausesCulled: file.vetoData[0]?.clausesCulled || '',
        clausesRevised: file.vetoData[0]?.clausesRevised as ClausesRevisedObject,
        id: file.vetoData[0]?.id || '',
      },
      references: file.mentions.map((mention) => {
        return {
          displayName: mention.targetDocument?.displayName || mention.title,
          name: mention.targetDocument?.name || mention.title,
          missing: mention.status === 'Missing',
          highlight: mention.locations,
          generatedTitle: mention.title,
        };
      }),
      referenced_by: file.mentionedBy.map((mention) => {
        return {
          displayName: mention.sourceDocument?.displayName || mention.title,
          name: mention.sourceDocument?.name || mention.title,
          missing: mention.status === 'Missing',
          highlight: mention.locations,
          generatedTitle: mention.title,
        };
      }),
    };
  }, []);

  const selectedFileDetails = useMemo(() => {
    if (!selectedFile) {
      return undefined;
    }
    return parseFileDetails(selectedFile);
  }, [parseFileDetails, selectedFile]);

  const secondSelectedFileDetails = useMemo(() => {
    if (!secondSelectedFile) {
      return undefined;
    }
    return parseFileDetails(secondSelectedFile);
  }, [parseFileDetails, secondSelectedFile]);

  const selectSecondFileByName = useCallback(
    (name: string) => {
      const chosenFile = dataRoomFiles.find((file) => {
        return file.name === name;
      });
      if (chosenFile) {
        setSecondSelectedFile(chosenFile);
      }
    },
    [dataRoomFiles],
  );

  const getCheckedFolders = (subfolders: string[]) => {
    const checkedFolders: string[] = [];
    subfolders.forEach((folder) => {
      const lastIndex = folder.lastIndexOf('/');
      const folderName = folder.substring(lastIndex);
      checkedFolders.push(folderName);
    });

    return checkedFolders;
  };

  const updateSubFolders = useCallback(
    (folderPath: string, isChecked: boolean, folderStatus: { [folder: string]: boolean }) => {
      const subfolders = folderConfig?.emptyFolders.filter((folder) =>
        folder.startsWith(folderPath),
      );

      if (subfolders && subfolders.length > 0) {
        subfolders.forEach((subFolder) => {
          folderStatus[subFolder] = isChecked;
        });

        if (isChecked) {
          getCheckedFolders(subfolders).forEach((folder) => {
            updateFolderSelection(folder, false);
          });
        } else {
          getCheckedFolders(subfolders).forEach((folder) => {
            updateFolderSelection(folder, true);
          });
        }
      }
    },
    [folderConfig?.emptyFolders, updateFolderSelection],
  );

  const changeFolderCheckStatus = useCallback(
    (checkStatus: boolean) => {
      if (folderConfig) {
        const filteredFolders = folderConfig.emptyFolders.filter(
          (folder) => folder !== '/' && !folder.startsWith('/~Trash'),
        );
        filteredFolders.sort();
        filteredFolders.forEach((path) => {
          if (path !== '/~Trash' && path !== '') {
            setIsFolderChecked((prevState) => {
              const folderCheckStatus = {
                ...prevState,
                [path]: !prevState[path],
              };
              updateSubFolders(path, checkStatus, folderCheckStatus);

              return folderCheckStatus;
            });
          }
        });
      }
    },
    [folderConfig, updateSubFolders],
  );

  return (
    <DataRoomContext.Provider
      value={{
        selectedFile,
        selectedFileUrl,
        setSelectedFile: onSelectFile,
        selectedRenameFile,
        setSelectedRenameFile,
        selectedFolder,
        setSelectedFolder,
        updateFolderSelection,
        updateAllFolderSelection,
        selectedFoldersArray,
        setSelectedFoldersArray,
        setDraggedContent,
        isCheckAllActive,
        setIsCheckAllActive,
        isViewerOpen,
        setIsViewerOpen,
        checkedFiles,
        setCheckedFiles,
        hasCheckedFiles,
        goToNextDocument,
        goToPreviousDocument,
        paths,
        dataRoomFiles,
        combinedDocumentArray,
        isFolderOpenByPath,
        setIsFolderOpenByPath,
        isFolderChecked,
        setIsFolderChecked,
        changeFolderCheckStatus,
        initialClickIndex,
        setInitialClickIndex,
        renameFileIndex,
        setRenameFileIndex,
        isFileRenameActive,
        updateRenameFormStatus,
        updateSignature,
        selectedFileDetails,
        secondSelectedFileDetails,
        selectSecondFileByName,
        secondSelectedFile,
        secondSelectedFileUrl,
        updateSubFolders,
      }}
    >
      {children}
    </DataRoomContext.Provider>
  );
}
