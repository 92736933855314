import * as d3 from 'd3';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DraggableScrollContainer } from '@/components/DraggableScrollContainer';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';
import { drawCluster } from '@/pages/overview/redline/clustering/d3/draw-cluster';
import { drawDocument } from '@/pages/overview/redline/clustering/d3/draw-document';
import { RedlineClusteringUploadFileModal } from '@/pages/overview/redline/clustering/RedlineClusteringUploadFileModal';
import {
  moveToCluster,
  removeFromCluster,
  uploadFileToCluster,
} from '@/pages/overview/redline/clustering/util';
import { useRedline } from '@/pages/overview/redline/useRedline';
import { changeTemplate } from '@/pages/overview/redline/utils';

export const RedlineClusteringContent = () => {
  const [processing, setProcessing] = useState(false);
  const [uploadingToCluster, setUploadingToCluster] = useState<string | undefined>();
  const navigate = useNavigate();
  const { matter } = useOverview();
  const { redlineName } = useParams();
  const { redline, refetch, loading } = useRedline(redlineName);
  const [focusedCluster, setFocusedCluster] = useState('');
  const onChangeTemplate = useCallback(
    async (clusterName: string, newTemplateFile: string) => {
      if (!redline) return null;
      try {
        setProcessing(true);
        changeTemplate({
          clientNumber: matter.client.number,
          matterNumber: matter.number,
          redlineName: redline.name,
          clusterName,
          newTemplateFile,
        });
        refetch();
      } finally {
        setProcessing(false);
      }
    },
    [matter.client.number, matter.number, redline, refetch],
  );
  const onMoveToCluster = useCallback(
    async (fileNames: string[], fromCluster: string, toCluster: string) => {
      if (!redline) return null;
      try {
        setProcessing(true);
        await moveToCluster({
          clientNumber: matter.client.number,
          matterNumber: matter.number,
          redlineName: redline.name,
          fromCluster,
          toCluster,
          fileNames,
        });
      } finally {
        setProcessing(false);
      }
      refetch();
    },
    [redline, refetch, matter.client.number, matter.number],
  );

  const onRemoveFromCluster = useCallback(
    async (fileName: string, clusterName: string) => {
      if (!redline) {
        return;
      }
      try {
        setProcessing(true);
        await removeFromCluster({
          clientNumber: matter.client.number,
          matterNumber: matter.number,
          redlineName: redline.name,
          clusterName,
          fileName,
        });
      } finally {
        setProcessing(false);
      }
      refetch();
    },
    [redline, refetch, matter.client.number, matter.number],
  );

  const onUploadFile = useCallback(
    async (files: File[]) => {
      if (!uploadingToCluster || !redline) {
        return;
      }
      await uploadFileToCluster({
        clientNumber: matter.client.number,
        matterNumber: matter.number,
        redlineName: redline.name,
        clusterName: uploadingToCluster,
        files,
      });
      refetch();
      setUploadingToCluster(undefined);
    },
    [matter.client.number, matter.number, redline, refetch, uploadingToCluster],
  );
  useEffect(() => {
    if (!redline) return;
    const clusters = redline.clusters;
    drawCluster(clusters, focusedCluster, {
      onFocus: (cluster: string) => {
        if (cluster === focusedCluster) return;
        d3.selectAll(`[data-cluster="${focusedCluster}"]`).attr('class', 'hidden');
        setFocusedCluster(cluster);
      },
      onEnd: () => {
        drawDocument(clusters, focusedCluster, {
          onChangeTemplate,
          onMoveToCluster,
          onRemoveFromCluster,
        });
      },
      onRedline: (id: string) => navigate(`${id}/diff`),
      onAddFile: setUploadingToCluster,
    });
  }, [redline, navigate, onChangeTemplate, onMoveToCluster, onRemoveFromCluster, focusedCluster]);
  return (
    <>
      {uploadingToCluster && (
        <RedlineClusteringUploadFileModal
          title="Upload to Cluster"
          isModalOpen={true}
          onUploadFile={onUploadFile}
          onClose={() => setUploadingToCluster(undefined)}
        />
      )}
      <div
        className={`gridlines`}
        onClick={(e: React.SyntheticEvent) => {
          (e.target as HTMLElement).id === 'cluster-canvas' && setFocusedCluster('');
        }}
      >
        <h1 className="absolute m-[16px] flex flex-row text-[34px] font-bold text-[#E6E6DF]">
          Redline
          {(processing || loading) && (
            <div className="px-[10px]">
              <LoadingSpinner size={10} />
            </div>
          )}
        </h1>
        <DraggableScrollContainer>
          <svg width="200%" height="200%" id="cluster-canvas">
            <g className={`cluster ${loading ? 'cursor-wait' : ''}`}></g>
            <g className="documents"></g>
          </svg>
        </DraggableScrollContainer>
      </div>
    </>
  );
};
export default RedlineClusteringContent;
