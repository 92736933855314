import { useCallback, useMemo, useState } from 'react';

import MarveriRename from '@/assets/images/marveri-rename.svg';
import RenameIcon from '@/assets/images/rename-icon.svg';
import { Dropdown } from '@/components/Dropdown';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { checkFileNameFormat, formatFileDisplay, useOverview } from '@/pages/overview/common/utils';
import { trpcReact } from '@/utils/trpc';

interface RenameOptionsProps {
  filesAreProcessing: boolean;
}

export const RenameOptions = ({ filesAreProcessing }: RenameOptionsProps) => {
  const [renameLoading, setRenameLoading] = useState(false);
  const { matter } = useOverview();
  const {
    checkedFiles,
    setCheckedFiles,
    hasCheckedFiles,
    setIsCheckAllActive,
    changeFolderCheckStatus,
  } = useDataRoom();
  const renameFileMutation = trpcReact.dataRoom.bulkUpdateDataRoomFileName.useMutation();

  const renameToMarveriFormat = useCallback(() => {
    setRenameLoading(true);
    const updates = matter.dataRoomFiles
      .filter((file) => {
        // Check if the file ID exists in the checkedFiles and the docType is not 'Cap Table'
        return (
          checkedFiles.some((checkedFile) => checkedFile.name === file.name) &&
          file.docType !== 'Cap Table'
        );
      })
      .map((file) => {
        return {
          name: file.name,
          newName: formatFileDisplay(file),
        };
      });

    renameFileMutation.mutate(
      {
        clientNumber: matter.client.number,
        clientMatterNumber: matter.number,
        updates: updates,
      },
      {
        onSettled: () => {
          setRenameLoading(false);
        },
      },
    );
    setCheckedFiles([]);
    setIsCheckAllActive(false);
    changeFolderCheckStatus(false);
  }, [
    changeFolderCheckStatus,
    checkedFiles,
    matter.client.number,
    matter.dataRoomFiles,
    matter.number,
    renameFileMutation,
    setCheckedFiles,
    setIsCheckAllActive,
  ]);

  const renameToOriginalFormat = useCallback(() => {
    setRenameLoading(true);
    const updates = checkedFiles
      .filter((f) => f.docType !== 'Cap Table')
      .map((file) => {
        return {
          name: file.name,
          newName: file.originalPathname.split('/').slice(-1)[0],
        };
      });
    renameFileMutation.mutate(
      {
        clientNumber: matter.client.number,
        clientMatterNumber: matter.number,
        updates: updates,
      },
      {
        onSettled: () => {
          setRenameLoading(false);
        },
      },
    );
    setCheckedFiles([]);
    setIsCheckAllActive(false);
    changeFolderCheckStatus(false);
  }, [
    changeFolderCheckStatus,
    checkedFiles,
    matter.client.number,
    matter.number,
    renameFileMutation,
    setCheckedFiles,
    setIsCheckAllActive,
  ]);

  const shouldShowOriginalNameOption =
    checkFileNameFormat(checkedFiles) === 'MARVERI' ||
    checkFileNameFormat(checkedFiles) === 'MIXED';
  const shouldShowMarveriNameOption =
    checkFileNameFormat(checkedFiles) === 'ORIGINAL' ||
    checkFileNameFormat(checkedFiles) === 'MIXED';

  const options = useMemo(() => {
    const opts = [];
    if (shouldShowOriginalNameOption) {
      opts.push({
        title: 'Original Name',
        icon: RenameIcon,
        onClick: renameToOriginalFormat,
      });
    }

    if (shouldShowMarveriNameOption) {
      opts.push({
        title: 'Marveri Name',
        icon: MarveriRename,
        onClick: renameToMarveriFormat,
      });
    }

    return opts;
  }, [
    shouldShowOriginalNameOption,
    shouldShowMarveriNameOption,
    renameToOriginalFormat,
    renameToMarveriFormat,
  ]);

  return (
    <Dropdown
      title="Rename"
      icon={RenameIcon}
      options={options}
      testId="rename-button"
      isButtonDisabled={!hasCheckedFiles || filesAreProcessing || renameLoading}
      processing={filesAreProcessing || renameLoading}
      resizeAction={true}
    />
  );
};
