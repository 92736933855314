import { DataRoomClientFile } from '@server/modules/client-matter/common/types';
import { useCallback, useEffect, useState } from 'react';

import { ClientMatter } from '@/common/types';
import { useNewDiligenceWizard } from '@/contexts/create-new-diligence-wizard/utils';
import { DataroomUploader } from '@/pages/home/content/DataroomUploader';
import { DiligenceCard } from '@/pages/home/content/DiligenceCard';
import { token, trpcReact } from '@/utils/trpc';

export const HomeContent = () => {
  const { updateNewDiligenceData } = useNewDiligenceWizard();
  useEffect(() => {
    localStorage.setItem('folderOpenMap', '');
  }, []);

  const [clientMatters, setClientMatters] = useState<ClientMatter[]>([]);
  trpcReact.clientMatter.getAllForCurrentUser.useQuery(undefined, {
    onSuccess: setClientMatters,
  });

  const addClientMatter = (matterToAdd: ClientMatter) => {
    setClientMatters((current) => {
      if (current.some((matter) => matter.number === matterToAdd.number)) {
        return current;
      }
      return [...current, matterToAdd];
    });
  };

  const removeClientMatter = (matterToRemove: ClientMatter) => {
    setClientMatters((current) => {
      if (!current.some((matter) => matter.client.number === matterToRemove.client.number)) {
        return current;
      }
      return current.filter((matter) => matter.client.number !== matterToRemove.client.number);
    });
  };

  interface StringDatedDataRoomClientFile extends Omit<DataRoomClientFile, 'date'> {
    date: string | null;
  }
  const convertToDate = (dataRoomFile: StringDatedDataRoomClientFile): DataRoomClientFile => {
    return {
      ...dataRoomFile,
      date: dataRoomFile.date ? new Date(dataRoomFile.date) : null,
    };
  };

  trpcReact.clientMatter.onChange.useSubscription(
    {
      token: token ?? '',
    },
    {
      enabled: token !== undefined,
      onData(changeEvent) {
        const { type, data } = changeEvent;
        const dataroomFiles = data.dataRoomFiles;
        // @ts-expect-error "type instantiation is excessively deep and possibly infinite"
        const datedDataRoomFiles: DataRoomClientFile[] = dataroomFiles.map(convertToDate);
        const updatedMatter = {
          ...data,
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
          dataRoomFiles: datedDataRoomFiles,
        };

        if (type === 'create') {
          addClientMatter(updatedMatter);
        } else {
          removeClientMatter(updatedMatter);
        }
      },
      onError() {},
    },
  );

  const clientMattersSorted = clientMatters.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const { newDiligenceData } = useNewDiligenceWizard();
  const [files, setFiles] = useState<File[] | undefined>(newDiligenceData.dataRoomFiles);

  const addFiles = useCallback((newFiles: File[]) => {
    setFiles((files) => {
      if (files) {
        return [...files, ...newFiles];
      } else {
        return newFiles;
      }
    });
  }, []);

  const deleteFiles = useCallback(
    (fileToDelete: File) => {
      const filteredFileList = files?.filter(
        (currentFile) => currentFile.name !== fileToDelete.name,
      );
      setFiles(filteredFileList);
      updateNewDiligenceData({ dataRoomFiles: filteredFileList });
    },
    [files, updateNewDiligenceData],
  );

  return (
    <div
      className={`relative z-[1] flex h-fit w-full flex-col bg-marveri-background px-[180px] pb-10 text-marveri-white`}
      data-testid="home-component"
    >
      <DataroomUploader
        onUploadDone={addFiles}
        onFileDelete={deleteFiles}
        totalCount={files?.length || 0}
      />
      <h1 className="text-[25px] font-bold text-marveri-white">Recently Created Document Sets</h1>
      <div
        className="md:grid-cols-2 mt-8 grid grid-cols-2 gap-[12px]"
        data-testid="home-matter-list"
      >
        {clientMattersSorted.map((matterItem) => {
          return (
            <DiligenceCard
              key={`${matterItem.client.number}&${matterItem.number}`}
              matterItem={{
                clientName: !matterItem.name ? matterItem.client.name : matterItem.name,
                number: matterItem.number,
                clientNumber: matterItem.client.number,
                numberOfFiles: matterItem._count.dataRoomFiles || 0,
              }}
            />
          );
        })}
      </div>
      {clientMattersSorted.length === 0 && (
        <div className="flex h-[10vh] w-full items-center justify-center text-center font-semibold text-marveri-light-silver">
          {' '}
          Uploaded document sets will appear here
        </div>
      )}
    </div>
  );
};
