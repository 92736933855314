import { useAuth0 } from '@auth0/auth0-react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ENVIRONMENT } from '@/constants';
import { ColoredNavLink } from '@/pages/root/Root';
import { trpcReact } from '@/utils/trpc';

const Header = () => {
  const flags = useFlags();
  const { logout } = useAuth0();
  const user = trpcReact.user.getCurrentUser.useQuery().data;

  useEffect(() => {
    if (user && user.email && ENVIRONMENT === 'production') {
      LogRocket.identify('user', {
        name: user?.firstName + ' ' + user?.lastName,
        email: user?.email || 'Unknown',
      });
    }
  }, [user]);

  const userInitials =
    user && user.firstName && user.lastName && user?.firstName[0] + user?.lastName[0];

  const userName = user && user.firstName + ' ' + user.lastName;

  const onLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleMailtoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open('mailto:support@marveri.com', '_blank');
  };

  return (
    <div className="z-20 flex items-center justify-between bg-black p-4" data-testid="header">
      <div className="flex items-center gap-4 pl-[10px]">
        <div>
          <Link
            to={'/'}
            className="cursor-pointer font-marveri-logo text-[35px] font-bold uppercase tracking-widest text-marveri-white"
          >
            Marveri
          </Link>
        </div>
      </div>
      <div className="flex items-center gap-6">
        <ColoredNavLink to={'home'}>Home</ColoredNavLink>
        <div className="flex cursor-default items-center gap-3 border-l border-light-border pl-4">
          {flags.feedbackButtons && (
            <div className="flex items-center gap-4">
              <Link
                className="w-[75px] rounded-[5px] border-2 border-[#2E2E2E] bg-container-dark p-[4px] text-center text-[13px] font-bold text-marveri-light-silver hover:bg-container-hover"
                to="#"
                onClick={handleMailtoClick}
                target="_blank"
              >
                Support
              </Link>
              <Link
                className="w-[75px] rounded-[5px] border-2 border-[#2E2E2E] bg-container-dark p-[4px] text-center text-[13px] font-bold text-marveri-light-silver hover:bg-container-hover"
                to="https://docs.google.com/forms/d/e/1FAIpQLSfhTXCOaS1GGOOViSQL0mY41gNNRCvZ6Bhcbbo3qOyO1NNMqQ/viewform"
                target="_blank"
              >
                Feedback
              </Link>
            </div>
          )}
          <Menu>
            <MenuButton
              className="flex size-12 items-center justify-center rounded-full bg-marveri-white text-[28px] font-bold text-black"
              data-testid="header-options-button"
            >
              {userInitials}
            </MenuButton>
            <MenuItems
              anchor="bottom"
              className="z-[100] w-[280px] -translate-x-4 translate-y-2 rounded-lg shadow-2xl"
            >
              <div className="flex flex-col items-center gap-2 rounded-t-[8px] bg-container-light-gray px-[16px] py-[12px]">
                <div className="flex size-12 items-center justify-center rounded-full bg-marveri-white text-[28px] font-bold text-black">
                  {userInitials}
                </div>
                <span className="text-marveri-white">{userName}</span>
              </div>
              <MenuItem>
                <div className="border-b-2 border-light-border bg-container-dark p-4">
                  <ColoredNavLink to={'settings/profile'}>Profile</ColoredNavLink>
                </div>
              </MenuItem>
              <MenuItem>
                <div className="border-b-2 border-light-border bg-container-dark p-4">
                  <ColoredNavLink to={'settings/security-privacy'}>
                    Security & Privacy
                  </ColoredNavLink>
                </div>
              </MenuItem>
              <MenuItem>
                <div
                  className="cursor-pointer bg-container-dark p-4 text-marveri-silver hover:text-marveri-light-gold"
                  onClick={onLogout}
                >
                  Logout
                </div>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default Header;
