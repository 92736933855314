import { useNavigate } from 'react-router-dom';

import ArrowIcon from '@/assets/images/arrow-icon.svg';
import ClusterIcon from '@/assets/images/clusters.svg';
import DocumentIcon from '@/assets/images/documents.svg';
import FileIcon from '@/assets/images/new-file-icon.svg';
import { RedlineData } from '@/common/types';
import { Cluster, Diff } from '@/pages/overview/redline/clustering/d3/draw-cluster';

export const RedlineClusteringSidebar = ({ redline }: { redline?: RedlineData }) => {
  const navigate = useNavigate();
  if (!redline) return;
  return (
    <>
      <div className="flex flex-col justify-between">
        <div className="flex items-center gap-4 border-b-2 border-light-border p-4 text-marveri-white">
          <span className="font-bold">Clustering</span>
        </div>
        <div className="border-b-2 border-light-border p-4 text-marveri-white">
          <h1 className="pb-2 font-bold text-marveri-light-silver">Summary</h1>
          <div className="min-h-[80px] overflow-y-auto scrollbar-thin scrollbar-thumb-marveri-muted-silver">
            <div className="flex flex-row py-[10px]">
              <img src={ClusterIcon} className="mx-[10px] my-[3px] size-[16px]" />
              {redline?.clusters.length || 0} Clusters
            </div>
            <div className="flex flex-row py-[10px]">
              <img src={DocumentIcon} className="mx-[10px] my-[3px] size-[16px]" />
              {redline?.redlineFiles.length || 0} Documents
            </div>
          </div>
        </div>
        <div className="h-[350px] overflow-y-scroll scrollbar-thin scrollbar-thumb-marveri-muted-silver">
          {redline.clusters.map((c: Cluster) => (
            <div className="border-b-2 border-light-border py-[9px]" key={c.id}>
              <details className="group">
                <summary className="flex cursor-pointer list-none items-center justify-between px-4 font-medium">
                  <h1 className="align-middle font-bold text-marveri-light-silver">
                    Cluster {c.name}
                  </h1>
                  <img
                    src={ArrowIcon}
                    className="mx-[10px] my-[3px] size-[16px] -rotate-90 transition group-open:rotate-0"
                  />
                </summary>
                <p className="mt-3 text-black">
                  {c.diffs.map((d: Diff) => (
                    <div
                      key={d.id}
                      className="flex cursor-pointer flex-row bg-container-selected py-[9px]"
                    >
                      <img src={FileIcon} className="mx-[10px] my-[3px] size-[16px]" />
                      <span className="text-white">{d.neighbor.name}</span>
                    </div>
                  ))}
                </p>
              </details>
            </div>
          ))}
        </div>
        <div
          className="m-auto mt-[80px] h-[32px] w-[95px] cursor-pointer rounded border pt-[3px] text-center align-middle text-marveri-white hover:bg-container-hover"
          onClick={() => navigate(`../`)}
        >
          Back
        </div>
      </div>
    </>
  );
};
