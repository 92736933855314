import { Outlet } from 'react-router-dom';

import { DataRoomContextProvider } from '@/contexts/overview/dataroom/DataRoomContext';
import { RedlinesContextProvider } from '@/contexts/overview/redlines/RedLinesContext';
import { useOverview } from '@/pages/overview/common/utils';

const Redline = () => {
  const { matter } = useOverview();
  return (
    <RedlinesContextProvider>
      <DataRoomContextProvider>
        <Outlet context={{ matter }} />
      </DataRoomContextProvider>
    </RedlinesContextProvider>
  );
};

export default Redline;
