import { forwardRef, PropsWithChildren, useEffect } from 'react';

import { DataRoomClientFile } from '@/common/types';

interface ScrollableDivProps {
  containerStyle?: string;
  selectedFile?: DataRoomClientFile;
}

export const ScrollableDiv = forwardRef<HTMLDivElement, PropsWithChildren<ScrollableDivProps>>(
  ({ children, containerStyle, selectedFile }, ref) => {
    //This useEffect contols the scroll functionality in the data room file list
    useEffect(() => {
      if (selectedFile) {
        setTimeout(() => {
          const selectedElement = document.getElementById(selectedFile.name);
          const container = document.getElementById('scrollable-div');
          if (selectedElement && container) {
            const selectedElementPosition = selectedElement.getBoundingClientRect();
            const containerPosition = container.getBoundingClientRect();
            //Finds the position of the selected element with the parent container
            const positionWithinContainer = {
              top: selectedElementPosition.top - containerPosition.top,
              bottom: selectedElementPosition.bottom - containerPosition.top,
            };
            //Threshold can be changed to contol when to scroll
            const threshold = 0;

            if (
              positionWithinContainer.top < threshold ||
              positionWithinContainer.bottom > containerPosition.height - threshold
            ) {
              selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }
        }, 100);
      }
    }, [selectedFile]);

    return (
      <div
        className={`${containerStyle} scroll-p-10 overflow-y-auto scrollbar scrollbar-thumb-marveri-muted-silver scrollbar-w-[10px] hover:scrollbar-thumb-marveri-light-silver active:scrollbar-thumb-marveri-silver`}
        data-testid="scrollable-div"
        id="scrollable-div"
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

ScrollableDiv.displayName = 'ScrollableDiv';
