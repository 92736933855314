import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Arrow from '@/assets/images/arrow-up.svg';
import BlueFileIcon from '@/assets/images/blue-file-icon.svg';
import TrashIcon from '@/assets/images/delete-icon.png';
import InformationIcon from '@/assets/images/information-icon.svg';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { useOverview } from '@/pages/overview/common/utils';
import { SimpleButton } from '@/pages/overview/dataroom/content/common/SimpleButton';
import { ClausesRevisedObject, VetoData } from '@/pages/overview/financing-rights/common/utils';
import { PdfViewer } from '@/pages/pdf-viewer/PdfViewer';
import { SlideInPdfViewer } from '@/pages/pdf-viewer/SlideInPdfViewer';
import { trpcClient } from '@/utils/trpc';

const defaultVetoData: VetoData[] = [];

const columnHelper = createColumnHelper<VetoData>();

interface CheckBoxProps {
  rowId: string;
}

export const FinancingRightsContent = () => {
  const flags = useFlags();
  const { matter, setIsPanelCollapsed } = useOverview();
  const { secondSelectedFile, secondSelectedFileUrl, selectSecondFileByName } = useDataRoom();

  const { selectedFile, setSelectedFile, selectedFileUrl, dataRoomFiles } = useDataRoom();

  const [data, setVetoData] = useState(() => [...defaultVetoData]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isSlideViewerOpen, setIsSlideViewerOpen] = useState(false);
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>();
  const [selectedHighlight, setSelectedHighlight] = useState<number[]>([]);
  const [selectedRowArray, setSelectedRowArray] = useState<string[]>([]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    source: true,
    documentType: true,
    coiFilingStatus: true,
    coiDuplicates: true,
    coiDocumentType: true,
    coiReferences: true,
    coiProforma: true,
    directorInformation: true,
    directorElection: true,
    authorizedSharesStructure: true,
    formationDate: true,
    jurisdiction: true,
    address: true,
    name: true,
  });

  const [sorting, setSorting] = useState<SortingState>([{ id: 'source', desc: false }]);

  const selectSourceFile = useCallback(
    (fileName: string, highlight: number[], rowIndex: number) => {
      const toSelect = dataRoomFiles.find((file) => {
        return file.name === fileName;
      });
      if (toSelect) {
        setSelectedFile(toSelect.name);
        setSelectedHighlight(highlight);
        setIsSlideViewerOpen(true);
        setIsPanelCollapsed(true);
        setSelectedRowIndex(rowIndex);
      }
    },
    [dataRoomFiles, setIsPanelCollapsed, setSelectedFile],
  );

  const handleToggleCheckbox = (index: string) => {
    setSelectedRowArray((prevselectedRowArray) => {
      if (prevselectedRowArray.includes(index)) {
        return prevselectedRowArray.filter((i) => i !== index);
      } else {
        return [...prevselectedRowArray, index];
      }
    });
  };

  const CheckBox = useCallback(
    ({ rowId }: CheckBoxProps) => {
      return (
        <input
          type="checkbox"
          checked={selectedRowArray.includes(rowId)}
          onChange={() => handleToggleCheckbox(rowId)}
          className={`size-4 shrink-0 cursor-pointer appearance-none border border-light-border bg-marveri-background bg-contain bg-center bg-no-repeat checked:bg-marveri-gold checked:bg-[url('@/assets/images/check-icon-white.svg')]`}
          data-testid="data-room-file-checkbox"
        />
      );
    },
    [selectedRowArray],
  );

  const deleteRefDoc = useCallback(
    (refDocId: string) => {
      selectedRowArray.filter((rowId) => rowId !== refDocId);
      trpcClient.dataRoom.deleteVetoDataById.mutate({
        clientMatterNumber: matter.number,
        clientNumber: matter.client.number,
        id: refDocId,
      });
    },
    [matter.client.number, matter.number, selectedRowArray],
  );

  const handleDeleteAll = () => {
    setSelectedRowArray([]);
    selectedRowArray.forEach((row) => {
      const rowToDelete = row.toString();
      deleteRefDoc(rowToDelete);
    });
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('clausesRevised.overall_title', {
        header: 'Overview',
        cell: (info) => {
          return (
            <div className="flex -translate-x-4 cursor-pointer flex-row items-center justify-center gap-4 py-1 pr-3 text-marveri-white">
              <CheckBox rowId={info.row.original.id} />
              <p>{'' + info.getValue()}</p>
            </div>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('clausesRevised.overall_explanation', {
        header: 'Description of Potential Rights',
        cell: (info) => {
          return (
            <>
              <p>{'' + info.getValue()}</p>
            </>
          );
        },
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('source', {
        header: 'Source',
        cell: (info) => (
          <div
            className="flex cursor-pointer flex-row justify-center hover:underline "
            onClick={() => {
              selectSourceFile(info.getValue().name, [], info.row.index);
            }}
          >
            <img className="mr-4" src={BlueFileIcon} />
            {info.getValue()?.displayName}
          </div>
        ),
        footer: (info) => info.column.id,
        sortingFn: (rowA, rowB) => {
          if (
            (rowA.original.source?.displayName || '') < (rowB.original.source?.displayName || '')
          ) {
            return -1;
          } else if (
            (rowA.original.source?.displayName || '') > (rowB.original.source?.displayName || '')
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      }),
    ];
  }, [CheckBox, selectSourceFile]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
      sorting,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
  });

  useEffect(() => {
    let rows = [] as VetoData[];
    rows = matter.dataRoomFiles
      .map((file) => {
        return file.vetoData.map((vetoClause) => {
          return {
            source: {
              displayName: file.displayName,
              name: file.name,
            },
            clausesFound: vetoClause.clausesFound,
            clausesCulled: vetoClause.clausesCulled,
            clausesRevised: vetoClause.clausesRevised as ClausesRevisedObject,
            id: vetoClause.id,
          };
        });
      })
      .flat();

    setVetoData(rows);
  }, [matter]);

  const closeDialog = useCallback(() => {
    setIsInfoDialogOpen(false);
  }, []);

  return (
    <>
      <div
        className="flex flex-col items-center bg-marveri-background text-[12px] text-marveri-white"
        data-testid="veto-debug-content"
      >
        <div className="w-[96%]">
          <div className="flex gap-2">
            <h1 className="mt-[16px] text-[34px] font-bold">Financing Rights</h1>
            <img
              src={InformationIcon}
              onClick={() => setIsInfoDialogOpen(true)}
              className="size-5 translate-y-[2.2rem] cursor-pointer"
            />
          </div>
          {selectedRowArray.length > 0 ? (
            <SimpleButton icon={TrashIcon} disabled={false} onClick={handleDeleteAll}>
              <span>
                Remove {selectedRowArray.length} Selected Document
                {selectedRowArray.length > 1 && 's'}
              </span>
            </SimpleButton>
          ) : (
            <div className="h-[36px]"></div>
          )}
          {flags.missingDocColumnSelector && (
            <div className="my-4">
              {table.getAllColumns().map((column) => (
                <label key={column.id} className="mx-1">
                  <input
                    checked={column.getIsVisible()}
                    disabled={!column.getCanHide()}
                    onChange={column.getToggleVisibilityHandler()}
                    type="checkbox"
                    name={column.id}
                  />
                  {column?.id}
                </label>
              ))}
            </div>
          )}
          <div className="w-full" data-testid="veto-debug-content-list">
            <ScrollableDiv containerStyle="h-[calc(100vh-240px)] rounded-xl border border-[#525252] mb-2">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="sticky top-0 z-10 border-b border-light-border bg-[#2E2E2E] font-medium text-[#A9A9A9]">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer' : ''
                          } select-none px-6 py-4`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <div className="flex flex-row p-2">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                            <span className="flex">
                              {header.column.getIsSorted() === 'asc' ? (
                                <img className="mr-1 align-middle" src={Arrow} />
                              ) : header.column.getIsSorted() === 'desc' ? (
                                <img className="mr-1 rotate-180 align-middle" src={Arrow} />
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map(
                    (row) =>
                      row.original.clausesRevised.overall_title !== '' && (
                        <tr
                          key={row.id}
                          className={`${
                            selectedRowIndex === row.index && 'bg-light-border'
                          } border-b border-light-border`}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="px-6 py-4 font-medium">
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                          ))}
                        </tr>
                      ),
                  )}
                </tbody>
              </table>
            </ScrollableDiv>
          </div>
        </div>
      </div>
      <Dialog
        open={isInfoDialogOpen}
        onClose={closeDialog}
        className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50 "
      >
        <DialogPanel className="flex w-[554px] flex-col items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark p-4 text-marveri-white">
          <DialogTitle></DialogTitle>
          <div>
            <p>
              Marveri deploys a variety of AI models to review every document uploaded to identify
              the following rights that may be triggered by a company’s preferred stock financing:
            </p>
            <br />
            <ul className="list-disc pl-5">
              <li>Information rights</li>
              <li>Notice requirements</li>
              <li>Board observers</li>
              <li>Protective provisions</li>
              <li>Preemptive or participation rights</li>
              <li>Rights of first offer</li>
              <li>Veto or approval rights</li>
            </ul>
            <br />
            <p>
              Note that the models are only directed to search for the above clauses in the context
              of a potential issuance of new stock in a preferred stock financing. As such, it is
              not directed to flag these rights if they are applicable to different contexts. For
              example, it won{`'`}t flag periodic information rights that aren{`'`}t triggered by
              preferred stock financings. Marveri is currently developing tools to detect and
              understand these clauses in a wider range of contexts.{' '}
            </p>
            <br />
            <p>
              Carefully review all AI output. Do not rely on the AI system as necessarily detecting
              all relevant clauses, whether listed above or not, that you may need to identify as
              part of a legal review.
            </p>
          </div>
          <div className="mt-4">
            <button
              className="mx-2 h-[38px] w-[100px] rounded border border-dark-border bg-container-darkest text-marveri-muted-silver hover:bg-container-hover"
              onClick={closeDialog}
            >
              OK
            </button>
          </div>
        </DialogPanel>
      </Dialog>
      {!!selectedFile && !!isSlideViewerOpen && (
        <SlideInPdfViewer
          title={selectedFile.displayName}
          selectedHighlight={selectedHighlight}
          originalTitle={selectedFile.name}
          closeSlideViewer={() => setIsSlideViewerOpen(false)}
          openPdfViewer={() => setIsViewerOpen(true)}
          setSelectedRowIndex={setSelectedRowIndex}
        />
      )}
      {!!selectedFile && !!isViewerOpen && !!selectedFileUrl ? (
        <PdfViewer
          title={selectedFile.displayName}
          originalTitle={selectedFile.name}
          fileUrl={selectedFileUrl}
          multipleDocuments={false}
          closePdfViewer={() => setIsViewerOpen(false)}
          highlightLocation={selectedHighlight}
          selectSecondFile={selectSecondFileByName}
          secondSelectedFile={secondSelectedFile}
          secondSelectedFileUrl={secondSelectedFileUrl}
          sidebarFocus="Veto"
        />
      ) : (
        <></>
      )}
    </>
  );
};
