import { useEffect, useState } from 'react';

import AlertIcon from '@/assets/images/alert-triangle.svg';
import Xicon from '@/assets/images/x-button-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';

interface MissingSignatureProps {
  fileName: string;
  focusedFileName?: string;
  isRenameActive?: boolean;
  containerStyle?: string;
  onClick: () => void;
}

export const MissingSignatureIndicator = ({
  fileName,
  focusedFileName,
  isRenameActive,
  containerStyle,
  onClick,
}: MissingSignatureProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isIconHovered, setIsIconHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowWidth]);

  return (
    <div
      className={`${containerStyle} ${
        focusedFileName === fileName && isRenameActive && 'hidden'
      } relative flex gap-2 rounded-[50px] bg-light-border p-[5px] px-[8px] text-black xlarge:p-2`}
      data-testid="missing-signature-container"
      onMouseEnter={() => setIsIconHovered(true)}
      onMouseLeave={() => setIsIconHovered(false)}
    >
      <img src={AlertIcon} className={`${windowWidth < 1440 ? 'block' : 'hidden'}`} />
      <div className="flex gap-2 xlarge:hidden">
        <span className="font-semibold text-marveri-white">{`Confirm Signature`}</span>
        <MarveriIcon icon={Xicon} onClick={onClick} iconStyle="pt-[1px] " iconType="other" />
      </div>
      {windowWidth < 1440 && (
        <div
          className={`${
            isIconHovered ? 'scale-100' : 'scale-0'
          } absolute right-0 z-10 flex w-[160px] origin-right translate-y-[-8px] justify-between rounded-[50px] bg-light-border p-[5px] px-[8px] transition-transform ease-in-out`}
        >
          <MarveriIcon icon={Xicon} onClick={onClick} iconStyle="pt-[1px]" iconType="other" />
          <span className="font-semibold text-marveri-white">{`Confirm Signature`}</span>
        </div>
      )}
    </div>
  );
};
