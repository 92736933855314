import { useState } from 'react';

import { LogoutModal } from '@/pages/settings/profile/LogoutModal';
import { trpcReact } from '@/utils/trpc';

export const Profile = () => {
  const user = trpcReact.user.getCurrentUser.useQuery().data;
  const userInitials = user && user?.firstName[0] + user?.lastName[0];
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  return (
    <div className="cursor-default" data-testid="profile-page">
      <div className="border-b-2 border-light-border py-6">
        <div className="flex size-24 items-center justify-center rounded-full bg-marveri-white text-[48px] font-bold text-black">
          {userInitials}
        </div>
      </div>
      <div className="flex justify-between border-b-2 border-light-border p-4 font-bold hover:bg-container-hover">
        <span className="text-marveri-light-silver">Name</span>
        <span className="text-marveri-white">
          {user?.firstName} {user?.lastName}
        </span>
      </div>
      <div className="flex justify-between border-b-2 border-light-border p-4 font-bold hover:bg-container-hover">
        <span className="text-marveri-light-silver">Email Address</span>
        <span className="text-marveri-white">{user?.email}</span>
      </div>
      <div className="pl-4 pt-4">
        <p className="text-marveri-light-silver">
          To update your name or email address, please email{' '}
          <a
            className="underline"
            href="mailto:support@marveri.com"
            target="_blank"
            rel="noreferrer"
          >
            support@marveri.com
          </a>
        </p>
      </div>
      <div className="pt-4">
        <div
          className=" flex h-[32px] w-[95px] cursor-pointer items-center justify-center rounded-[5px] border-2 border-dark-border bg-container-dark text-marveri-light-silver hover:bg-container-hover"
          onClick={() => setIsLogoutModalOpen(true)}
          data-testid="logout-button"
        >
          Logout
        </div>
      </div>
      <LogoutModal
        isLogoutModalOpen={isLogoutModalOpen}
        setIsLogoutModalOpen={setIsLogoutModalOpen}
      />
    </div>
  );
};
