import { Dialog } from '@headlessui/react';

import FileIcon from '@/assets/images/new-file-icon.svg';
import ExitIcon from '@/assets/images/x-button-icon.svg';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';

export type Change = {
  id: string;
  inserted: string;
  fileName: string;
  type: string;
  deleted: string;
};

export const RedlineDiffingAlternateChangesModal = ({
  isModalOpen,
  setIsModalOpen,
  changes,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  changes: Change[];
}) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-marveri-background/50"
    >
      <Dialog.Panel className={`flex w-[500px] flex-col rounded-[5px] bg-container-dark`}>
        <div className="flex items-center justify-between border-b-2 border-b-light-border p-4">
          <h1 className="w-full text-center font-bold text-marveri-white">Changes</h1>
          <MarveriIcon
            icon={ExitIcon}
            iconStyle={'h-[18px] w-[18px]'}
            iconType="other"
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div className="flex flex-col py-[10px] text-marveri-light-silver">
          <div className="flex flex-col text-[16px] text-marveri-white ">
            {changes.map((c) => (
              <div
                key={c.id}
                className="flex border-b border-dark-border py-[10px]
  pl-[20px] hover:bg-container-hover"
              >
                <div className="w-full">
                  <div
                    className={`relative flex cursor-pointer items-center justify-between gap-[14px] border-b border-dark-border last:border-b-0 `}
                  >
                    <div className="flex items-center gap-[12px]">
                      <img src={FileIcon} alt="file-icon" className="h-[16px]" />
                      <span>{c.fileName}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
