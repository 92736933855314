import { useState } from 'react';

import DownloadIcon from '@/assets/images/download-icon.svg';
import { DataRoomClientFile } from '@/common/types';
import { useDataRoom } from '@/contexts/overview/dataroom/utils';
import {
  ConfirmationDialog,
  DialogViewState,
} from '@/pages/overview/dataroom/content/common/ConfirmationDialog';
import { DataRoomButton } from '@/pages/overview/dataroom/content/common/DataRoomButton';
import { FilesByPath } from '@/pages/overview/dataroom/content/common/types';
import { downloadOrganizedDataRoomFiles } from '@/pages/overview/dataroom/content/common/utils';

interface DownloadButtonProps {
  clientMatterNumber: number;
  clientNumber: number;
  clientName: string | null;
  files: DataRoomClientFile[];
  filesByPath: FilesByPath;
  setAllFilesUnchecked: () => void;
  filesAreProcessing: boolean;
}

export const DownloadButton = ({
  clientMatterNumber,
  clientNumber,
  clientName,
  files,
  filesByPath,
  setAllFilesUnchecked,
  filesAreProcessing,
}: DownloadButtonProps) => {
  const { hasCheckedFiles, changeFolderCheckStatus, selectedFoldersArray } = useDataRoom();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [viewState, setViewState] = useState<DialogViewState>('ready');

  const closeDialog = () => {
    setIsDialogOpen(false);
    setViewState('ready');
  };

  const onSubmit = async () => {
    setViewState('submitting');

    // The button should be disable/not displayed if no files are selected, but we add this just in case the user gets around that
    if (files.length === 0) {
      throw Error('No files selected');
    }

    try {
      await downloadOrganizedDataRoomFiles(
        clientMatterNumber,
        clientNumber,
        clientName,
        filesByPath,
        selectedFoldersArray,
      );
      closeDialog();
      setAllFilesUnchecked();
      changeFolderCheckStatus(false);
    } catch (e) {
      setViewState('errored');
    }
  };

  return (
    <DataRoomButton
      title="Download"
      icon={DownloadIcon}
      onClick={() => setIsDialogOpen(true)}
      testId="download-files-button"
      isDisabled={!hasCheckedFiles || filesAreProcessing}
      isDialogOpen={isDialogOpen}
    >
      <ConfirmationDialog
        title="Download Selected Documents?"
        submitButtonText="Download"
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        onSubmit={onSubmit}
        onCancel={closeDialog}
        viewState={viewState}
      />
    </DataRoomButton>
  );
};
