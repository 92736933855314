import axios from 'axios';
import { FileWithPath } from 'file-selector';
import groupBy from 'lodash/groupBy';

import { trpcClient } from '@/utils/trpc';
export const dashNumberRegex = /-\d+(\.\d+)?$/;

export const FILE_UPLOAD_ACCEPT_OBJECT = {
  'application/msword': ['.doc'],
  'application/pdf': ['.pdf'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'text/plain': ['.txt'],
};

export const ACCEPTED_TYPES = Object.keys(FILE_UPLOAD_ACCEPT_OBJECT);

export const ACCEPTED_EXTENSIONS = Object.values(FILE_UPLOAD_ACCEPT_OBJECT)
  .flatMap((f) => f)
  .sort();

/**
 * Generates a unique name by checking against a list of existing names.
 * If the provided name already exists in the list, the function will append an incremented suffix
 * (e.g., "-1", "-2") to create a unique name.
 *
 * The function handles names with and without file extensions differently.
 * For names with supported file extensions, the suffix is added before the extension.
 *
 * @param {string} name - The original name that needs to be made unique.
 * @param {string[]} existingNames - A list of names that the new name should be unique against.
 * @returns {string} - A unique name that does not exist in the existingNames array.
 */
export function renameGivenExistingNamesForDuplicates(name: string, existingNames: string[]) {
  let newName = name;

  const supportedFileExtensions = Object.values(FILE_UPLOAD_ACCEPT_OBJECT).flatMap((v) => v);
  if (supportedFileExtensions.filter((ext) => name.endsWith(ext)).length > 0) {
    const fileExtension = name.split('.').pop();
    let originalPrefix = name.substring(0, name.lastIndexOf('.'));

    // Remove duplicate endings when renaming (e.g. -1, -100, etc.)
    const dupeEnding = dashNumberRegex.exec(originalPrefix);
    if (dupeEnding) {
      const dupeEndingString = dupeEnding[0];
      originalPrefix = originalPrefix.substring(0, originalPrefix.lastIndexOf(dupeEndingString));
    }

    let i = 1;
    while (existingNames.includes(newName)) {
      newName = `${originalPrefix}-${i}.${fileExtension}`;
      i++;
    }
  } else {
    const dupeEnding = dashNumberRegex.exec(name);
    if (dupeEnding) {
      const dupeEndingString = dupeEnding[0];
      name = name.substring(0, name.lastIndexOf(dupeEndingString));
    }

    let i = 1;
    while (existingNames.includes(newName)) {
      newName = `${name}-${i}`;
      i++;
    }
  }

  return newName;
}

/**
 * Goes through a set of files and renames any files that have the same name as an existing file.
 *
 * @param existingFileNames - The names of the files that already exist  (optional)
 */
export function renameDuplicateFiles(files: FileWithPath[], existingFileNames: string[] = []) {
  const usedFileNames = existingFileNames;

  return files.map((file) => {
    // TODO: there is probably a more efficient way to do this

    const fileName = file.path || file.name;
    const newName = renameGivenExistingNamesForDuplicates(fileName, usedFileNames);

    usedFileNames.push(newName);

    return new File([file], newName, { type: file.type });
  });
}

interface UploadData {
  file: File;
  signedRequest: string;
  url: string;
  name: string;
  fields: { key: string; value: string }[];
  mimeType: string;
}
/**
 * Uploads a file to a remote server using a signed URL.
 *
 * @param {Object} data - An object containing the following properties:
 *   - file: The File object to be uploaded.
 *   - signedRequest: The signed URL for file upload.
 *   - url: The URL where the file will be accessible after upload.
 *   - name: The name of the uploaded file.
 *   - fields: An array of objects with 'key' and 'value' properties for additional form fields.
 *   - fileExtension: The file extension used to determine the MIME type of the file.
 *
 * @throws {Error} If the file extension is not valid or if an error occurs during the upload.
 *
 * @returns {Object} An object with 'url' and 'name' properties representing the uploaded file's URL and name.
 */
async function uploadFile(data: UploadData) {
  const { file, signedRequest, url, name, fields, mimeType } = data;

  const formData = new FormData();
  fields.forEach((field) => {
    formData.append(field.key, field.value);
  });

  formData.append('content-type', mimeType);
  formData.append('file', file);

  try {
    await axios.post(signedRequest, formData);
  } catch (error) {
    throw new Error(`Error uploading file: ${error}`);
  }

  return { url, name };
}

/**
 * Uploads a file to a remote server using a presigned POST request.
 *
 * This function simplifies the process of uploading a file by obtaining a presigned
 * POST request URL and then using that URL to upload the file to a remote server or service.
 *
 * @param {File} file - The file to be uploaded.
 * @param {number} clientNumber - A numerical identifier for the client.
 * @param {number} matterNumber - A numerical identifier for the matter or case associated with the file.
 * @throws {Error} If the file extension is invalid or if there is an error during the upload process.
 * @returns {Promise<{ url: string, name: string }>} An object containing the URL where the uploaded file
 *   can be accessed and the name of the uploaded file.
 */
export async function getPresignedPostAndUpload(
  file: File,
  clientNumber: number,
  matterNumber: number,
) {
  const name = file.name;
  const mimeType = file.type;

  const presignedPostResponse = await trpcClient.aws.getPresignedPost.query({
    clientNumber,
    matterNumber,
    mimeType,
  });

  return await uploadFile({
    file,
    name,
    signedRequest: presignedPostResponse.presignedRequest,
    url: presignedPostResponse.url,
    fields: presignedPostResponse.fields,
    mimeType,
  });
}

/**
 * Asynchronously retrieves presigned POST data for multiple files and uploads them.
 * This function groups files by their MIME type, retrieves presigned POST data for each group,
 * and then uploads each file using the provided presigned POST data.
 *
 * @param {File[]} files - An array of `File` objects to upload. `File` should contain properties like `name` and `type`.
 * @param {number} clientNumber - The client number associated with the files, used to get the presigned URLs.
 * @param {number} matterNumber - The matter number associated with the files, also used for presigned URLs.
 * @returns {Promise<{url: string; name: string}[][]>} - A promise that resolves to a 2D array where each sub-array
 * contains objects with `url` and `name` properties, representing the URLs where files were uploaded and their respective names.
 * Each sub-array corresponds to a group of files with the same MIME type.
 */
export async function bulkGetPresignedPostAndUpload(
  files: File[],
  clientNumber: number,
  matterNumber: number,
): Promise<{ successfulUploads: { url: string; name: string }[][]; failedFiles: string[] }> {
  const filesByType = groupBy(files, 'type');
  const types = Object.keys(filesByType);
  const failedFiles: string[] = [];

  const uploadResults: { url: string; name: string }[][] = await Promise.all(
    types.map(async (type) => {
      const filesOfType = filesByType[type];
      const presignedPostsResponse = await trpcClient.aws.getPresignedPosts.query({
        clientNumber,
        matterNumber,
        mimeType: type,
        amount: filesOfType.length,
      });

      const uploads = await Promise.all(
        filesOfType.map(async (file, index) => {
          try {
            return await retryUpload(
              {
                file,
                name: file.name,
                signedRequest: presignedPostsResponse[index].presignedRequest,
                url: presignedPostsResponse[index].url,
                fields: presignedPostsResponse[index].fields,
                mimeType: type,
              },
              3,
            );
          } catch (error) {
            failedFiles.push(file.name);
            return null; // Return null for failed uploads
          }
        }),
      );

      return uploads.filter((upload): upload is { url: string; name: string } => upload !== null); // Filter out the null values representing failed uploads
    }),
  );
  return {
    successfulUploads: uploadResults,
    failedFiles: failedFiles,
  };
}

async function retryUpload(
  data: UploadData,
  retries: number,
): Promise<{ url: string; name: string }> {
  try {
    return await uploadFile(data);
  } catch (error) {
    if (retries > 0) {
      return retryUpload(data, retries - 1);
    } else {
      throw error;
    }
  }
}

export const processingText = [
  'Encrypting Documents',
  'OCRing Documents',
  'Analyzing Documents',
  'Extracting Parties',
  'Determining Effective Dates',
  'Analyzing Document Content',
  'Determining Required Signatories',
  'Analyzing Signatures',
  'Flagging Missing Signatures',
  'Organizing Documents into Folders',
  'Analyzing Document Connections',
  'Searching for Missing Documents',
];
