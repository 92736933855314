import { MouseEventHandler, PropsWithChildren } from 'react';

export interface ButtonProperties extends PropsWithChildren {
  disabled: boolean;
  className?: string;
  onClick?: MouseEventHandler;
}

export const Button = (properties: ButtonProperties) => {
  const getButtonClasses = (disabled: boolean) => {
    if (disabled) {
      return 'bg-[#1B1B1B] border-[1px] border-dark-border text-marveri-light-silver';
    }

    return 'bg-marveri-gold text-[#1B1B1B] border-[1px] border-dark-border cursor-pointer hover:bg-[#FFD671]';
  };

  return (
    <button
      type="submit"
      disabled={properties.disabled}
      className={`flex h-[44px] w-[200px] items-center justify-center gap-[7px] rounded-[5px] duration-[0.5s] ${getButtonClasses(
        properties.disabled,
      )} ${properties.className ?? ''} `}
      onClick={properties.onClick}
      data-testid="marveri-button"
    >
      {properties.children}
    </button>
  );
};
